import { Box, Button, Card, Container, FormLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { createCategory, deleteUploads, updateCategory, uploadFile } from '../../helper/backend_helper';
import appConfig from '../../config';

const UpdateCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;
 
  const [formData, setFormData] = useState({ profileImage: null });
  const [title, setTitle] = useState(userData.name);
  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
     
      if (!title) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Please fill in all fields!',
        });
        setLoading(false);
        return;
      }
      if(formData?.profileImage!==null){
        console.log(userData)
        const header = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const data = {
          filename:userData?.image
        }
        const delUploads = await deleteUploads(data , header); 
        console.log(delUploads)
      }
      // Prepare headers for multipart form data
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      // Create a FormData object
      const data = new FormData();
      data.append('image', formData.profileImage);
     


      // Upload the image first
      const imageUploadResponse = await uploadFile(data, headers);
      console.log(imageUploadResponse)
      // Check if the image upload was successful
      if (imageUploadResponse.status !== 200) {
        throw new Error('Image upload failed');
      }

      const Categorydata = {
        name: title,
        image: imageUploadResponse?.data,
      };
    

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateCategory(userData?._id, Categorydata, Categoryheaders);
   
      // setTitle('');
      // setFormData({
      //   ...formData,
      //   profileImage: null,
      // });

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Category updated successfully',
      });

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error while updating category',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Category | Update Categories </title>
      </Helmet>
      <Card sx={{ padding: 1 }}>
        <Container>
          <Stack
            onClick={() => navigate(-1)}
            gap={3}
            sx={{ cursor: 'pointer', mb: 3 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <FaArrowLeftLong size={24} />
            <Typography variant="h4">Update Category</Typography>
          </Stack>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '50ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Title</FormLabel>
                <TextField label="Enter title" id="outlined-size-small" value={title} onChange={handleTitleChange} />
              </Stack>
            </Stack>

            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 2,
                marginTop: 2,
              }}
            >
              <Stack>
                {!formData.profileImage ? (
                  <img
                    src={`${appConfig.FILE_URL}${userData?.image}`}
                    alt="img"
                    style={{ maxWidth: '300px', borderRadius: 10 }}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(formData.profileImage)}
                    alt="img"
                    style={{ maxWidth: '300px', borderRadius: 10 }}
                  />
                )}
              </Stack>
              <Button
                sx={{
                  width: 170,
                  color: '#FF0000',
                  borderColor: '#FF0000',
                  '&:hover': {
                    borderColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                component="label"
                variant="outlined"
                size="medium"
                startIcon={<SlCloudUpload />}
              >
                upload Image
                <input
                  type="file"
                  accept="image/*"
                  name="profileImage"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Button>
            </Stack>
          </Box>
          <LoadingButton
            sx={{
              width: 100,
              mt: 4,
              mb: 4,
              boxShadow: 'none',
              backgroundColor: '#FF0000',
              '&:hover': {
                backgroundColor: '#e50000',
                boxShadow: 'none',
              },
            }}
            variant="contained"
            onClick={handleSave}
            loading={loading}
          >
            Update
          </LoadingButton>
        </Container>
      </Card>
    </>
  );
};

export default UpdateCategory;
