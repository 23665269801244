import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';

// components
import Swal from 'sweetalert2';
import { postJwtLogin } from '../../../helper/backend_helper';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  });

  const handleLogin = async (values) => {
    try {
      setLoading(true);
      const response = await postJwtLogin(values);
      if (
        response.data.data.user.role &&
        response.data.data.user.role.name !== "admin"
      ) {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Invalid email or password",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }
      localStorage.setItem('technisches', JSON.stringify(response.data.data));
      setLoading(false);
      navigate('/app', { replace: true });
      window.location.reload()
    } catch (error) {
      setLoading(false);
      if (error?.response?.status >= 400 || error?.response?.status < 500) {
        if (typeof value === 'string') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Invalid email or password',
            showConfirmButton: false,
            timer: 2000,
            
          });
        }
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Invalid email or password',
          showConfirmButton: false,
          timer: 2000,
         
        });
      }
    }
  };
  

  

  const formik = useFormik({
    initialValues: {
      email: '',
      password:''
    },
    validationSchema,
    onSubmit: (values) => handleLogin(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <Stack>
          <FormLabel sx={{mb:1}} >Email</FormLabel>
          <TextField
            label="Email address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Stack>
        <Stack>
          <FormLabel  sx={{mb:1}}>Password</FormLabel>
          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleLogin}
        loading={loading}
        sx={{
          backgroundColor: '#3A5239',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#3A5239',
            color: '#ffffff',
          },
        }}
      >
        Login
      </LoadingButton>
    </form>
  );
}
