import React from "react";
import { Image } from "@nextui-org/react";
import styles from "./chatItem.module.css";

const ChatItem = ({ avatar, alt, title, subtitle, date, unread, onClick }) => {
  const handleClick = () => {
    // Your existing click handler logic
    onClick();
  };

  const handleKeyDown = (event) => {
    // Handle keyboard events if needed
    if (event.key === "Enter" || event.key === " ") {
      // Trigger the same logic as the click event
      handleClick();
    }
  };

  return (
    <div
      className={styles.chatItem}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <Image
        className={styles.avatar}
        src={avatar}
        alt={alt}
        height={50}
        width={50}
      />
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <p className={styles.subtitle}>{subtitle}</p>
        <small className={styles.date}>{date}</small>
        {unread > 0 && <span className={styles.unread}>{unread}</span>}
      </div>
    </div>
  );
};

export default ChatItem
