import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { Player } from 'video-react';

import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { SlCloudUpload } from 'react-icons/sl';
import { MdDeleteOutline } from 'react-icons/md';
import { CiEdit } from 'react-icons/ci';
import image from '../../assets/Group.png';
import video from '../../assets/video.mp4';
import { createSolution, getCategories, uploadFile } from '../../helper/backend_helper';
import YoutubeEmbed from './youTube';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  // height: 400,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const AddSolution = () => {
  const navigate = useNavigate();
  const [previewData, setPreviewData] = React.useState([]);

  const [editingIndex, setEditingIndex] = React.useState(null);
  const [editingType, setEditingType] = React.useState(null);
  const handleEditItem = (index) => {
    setEditingIndex(index);

    setEditingType(previewData[index].type);
    switch (previewData[index].type) {
      case 'title':
        handleOpenTitleModal();
        break;
      case 'subTitle':
        setSubTitle(previewData[index]?.name);
        handleOpenSubTitleModal();
        break;
      // case 'link':
      //   setLink(previewData[index]?.name);
      //   handleOpenLinkModal();
      //   break;
      case 'text':
        setText(previewData[index]?.name);
        handleOpenTextModal();
        break;
      case 'avatar':
        setimageLogoData({ solutionImageLogo: previewData[index].name });
        handleOpenImageLogoModal();
        break;
      case 'image':
        setimageData({ solutionImage: previewData[index].name });
        handleOpenImageModal();
        break;
      case 'link':
        setPDFData({ solutionPDF: previewData[index].name });
        handleOpenPDFModal();
        break;
      case 'youTube':
        handleOpenVideoModal();
        break;
      default:
        break;
    }
  };

  const [isDisable, setIsDisable] = React.useState(false);
  const handleFirstButtonClick = () => {
    setIsDisable(true);
  };

  const [isDisableavatar, setIsDisableAvatar] = React.useState(false);
  const handleAddAvatar = () => {
    setIsDisableAvatar(true);
  };
  const [category, setCategory] = React.useState('');
  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const [categories, setCategories] = React.useState([]);

  async function fetchData() {
    try {
      const categoriesResponse = await getCategories();
      const filteredCategories = categoriesResponse?.data?.data;
      setCategories(filteredCategories);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  // title
  const [title, setTitle] = React.useState('');
  const [displayedTitle, setDisplayedTitle] = React.useState('');
  const [titleModal, setTitleModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleOpenTitleModal = () => {
    if (editingIndex !== null && editingType === 'title') {
      setTitle(previewData[editingIndex].name);
      setTitleModal(true);
    }
    setTitleModal(true);
  };

  // const handleOpenTitleModal = () => setTitleModal(true);
  const [loading, setLoading] = React.useState(false);
  const handleCloseTitleModal = () => setTitleModal(false);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleAddTitle = () => {
    if (title.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      const existingTitleIndex = previewData.findIndex((item) => item.type === 'title');
      if (existingTitleIndex !== -1) {
        const updatedPreviewData = [...previewData];
        updatedPreviewData[existingTitleIndex] = { name: title, type: 'title' };
        setPreviewData(updatedPreviewData);
      } else {
        setPreviewData([...previewData, { name: title, type: 'title' }]);
      }
      setDisplayedTitle(title);
      setErrorMessage('');
      handleCloseTitleModal();
      handleFirstButtonClick();
      // setTitle('');
      setEditingIndex(null);
      setEditingType(null);
    }
  };

  const handleCloseSnackbar = () => {
    setErrorMessage('');
  };

  //  solution logo avater
  const [imageLogoDisplayed, setImageLogoDisplayed] = React.useState('');

  const [imageLogoModal, setImageLogoModal] = React.useState(false);
  const handleOpenImageLogoModal = () => setImageLogoModal(true);
  const handleCloseImageLogoModal = () => setImageLogoModal(false);
  const [imageLogoData, setimageLogoData] = React.useState({ solutionImageLogo: null });
  const handelImageLogoChange = (e) => {
    const { name, files } = e.target;
    setimageLogoData({
      ...imageLogoData,
      [name]: name === 'solutionImageLogo' ? files[0] : e.target.value,
    });
  };

  const handleAddImageLogo = () => {
    if (imageLogoData?.solutionImageLogo) {
      // Replace the existing image in the previewData array
      const updatedPreviewData = [...previewData];
      updatedPreviewData[editingIndex] = {
        name: imageLogoData?.solutionImageLogo,
        type: 'avatar',
      };
      setPreviewData(updatedPreviewData);

      // Clear the imageData state
      setimageLogoData({ solutionImageLogo: null });
      setImageLogoDisplayed(imageLogoData?.solutionImageLogo);
    }
    handleCloseImageLogoModal();
  };

  const [imageLogoModalNew, setImageLogoModalNew] = React.useState(false);
  const handleOpenImageLogoModalNew = () => setImageLogoModalNew(true);
  const handleCloseImageLogoModalNew = () => setImageLogoModalNew(false);
  const [imageLogoDataNew, setimageLogoDataNew] = React.useState({
    solutionImageLogoNew: null,
  });
  const handelImageLogoChangeNew = (e) => {
    const { name, files } = e.target;
    setimageLogoDataNew({
      ...imageLogoDataNew,
      [name]: name === 'solutionImageLogoNew' ? files[0] : e.target.value,
    });
  };

  const handleAddImageLogoNew = () => {
    if (imageLogoDataNew?.solutionImageLogoNew) {
      setPreviewData([...previewData, { name: imageLogoDataNew?.solutionImageLogoNew, type: 'avatar' }]);
      setImageLogoDisplayed(imageLogoDataNew?.solutionImageLogoNew);
    }
    setimageLogoDataNew(null);
    handleCloseImageLogoModalNew();
    handleAddAvatar();
  };

  // subtitle
  const [subTitle, setSubTitle] = React.useState('');
  const [subTitleNew, setSubTitleNew] = React.useState('');
  const [subTitleModal, setSubTitleModal] = React.useState(false);
  const [subTitleModalNew, setSubTitleModalNew] = React.useState(false);
  const handleOpenSubTitleModalNew = () => setSubTitleModalNew(true);
  const handleOpenSubTitleModal = () => {
    // if (editingIndex !== null && editingType === 'subTitle') {
    // setSubTitle(previewData[editingIndex]?.name);
    setSubTitleModal(true);
    // }
    // setSubTitleModal(true);
  };
  const handleCloseSubTitleModal = () => setSubTitleModal(false);
  const handleCloseSubTitleModalNew = () => setSubTitleModalNew(false);
  const handlesubTitleChange = (event) => {
    setSubTitle(event.target.value);
  };
  const handlesubTitleChangeNew = (event) => {
    setSubTitleNew(event.target.value);
  };
  const handleAddsubTitle = () => {
    if (subTitle.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // setPreviewData([...previewData, { name: subTitle, type: 'subTitle' }]);
      // setErrorMessage('');
      // setSubTitle('');
      // handleCloseSubTitleModal();
      // const existingTitleIndex = previewData.findIndex((item) => item.type === 'subTitle');

      if (editingIndex !== -1) {
        const updatedPreviewData = [...previewData];
        updatedPreviewData[editingIndex] = { name: subTitle, type: 'subTitle' };
        setPreviewData(updatedPreviewData);
      } else {
        setPreviewData([...previewData, { name: subTitle, type: 'subTitle' }]);
      }
      setSubTitle('');
      setErrorMessage('');
      handleCloseSubTitleModal();
      handleFirstButtonClick();
      setEditingIndex(null);
      setEditingType(null);
    }
  };
  const handleAddsubTitleNew = () => {
    if (subTitleNew.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      setPreviewData([...previewData, { name: subTitleNew, type: 'subTitle' }]);
      setErrorMessage('');
      setSubTitleNew('');
      handleCloseSubTitleModalNew();
    }
  };

  // pdf file link
  const [link, setLink] = React.useState('');
  const [linkNew, setLinkNew] = React.useState('');
  const [linkModal, setLinkModal] = React.useState(false);
  const [linkModalNew, setLinkModalNew] = React.useState(false);
  const handleOpenLinkModalNew = () => setLinkModalNew(true);
  const handleOpenLinkModal = () => {
    // if (editingIndex !== null && editingType === 'subTitle') {
    // setSubTitle(previewData[editingIndex]?.name);
    setLinkModal(true);
    // }
    // setSubTitleModal(true);
  };
  const handleCloseLinkModal = () => setLinkModal(false);
  const handleCloseLinkModalNew = () => setLinkModalNew(false);
  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };
  const handleLinkChangeNew = (event) => {
    setLinkNew(event.target.value);
  };
  const handleAddLink = () => {
    if (link.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // setPreviewData([...previewData, { name: subTitle, type: 'subTitle' }]);
      // setErrorMessage('');
      // setSubTitle('');
      // handleCloseSubTitleModal();
      // const existingTitleIndex = previewData.findIndex((item) => item.type === 'subTitle');

      if (editingIndex !== -1) {
        const updatedPreviewData = [...previewData];
        updatedPreviewData[editingIndex] = { name: link, type: 'link' };
        setPreviewData(updatedPreviewData);
      } else {
        setPreviewData([...previewData, { name: link, type: 'link' }]);
      }
      setLink('');
      setErrorMessage('');
      handleCloseLinkModal();
      handleFirstButtonClick();
      setEditingIndex(null);
      setEditingType(null);
    }
  };
  const handleAddLinkNew = () => {
    if (linkNew.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      setPreviewData([...previewData, { name: linkNew, type: 'link' }]);
      setErrorMessage('');
      setLinkNew('');
      handleCloseLinkModalNew();
    }
  };

  // text
  const [text, setText] = React.useState('');
  const [textModal, setTextModal] = React.useState(false);
  // const handleOpenTextModal = () => setTextModal(true);
  const handleOpenTextModal = () => {
    // if (editingIndex !== null && editingType === 'text') {
    // setText(previewData[editingIndex]?.name);
    setTextModal(true);
    // }
    // setSubTitleModal(true);
  };
  const handleCloseTextModal = () => setTextModal(false);
  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleAddText = () => {
    if (text.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // setPreviewData([...previewData, { name: subTitle, type: 'subTitle' }]);
      // setErrorMessage('');
      // setSubTitle('');
      // handleCloseSubTitleModal();
      // const existingTitleIndex = previewData.findIndex((item) => item.type === 'subTitle');

      if (editingIndex !== -1) {
        const updatedPreviewData = [...previewData];
        updatedPreviewData[editingIndex] = { name: text, type: 'text' };
        setPreviewData(updatedPreviewData);
      } else {
        setPreviewData([...previewData, { name: text, type: 'text' }]);
      }
      setText('');
      setErrorMessage('');
      handleCloseTextModal();
      handleFirstButtonClick();
      setEditingIndex(null);
      setEditingType(null);
    }
  };
  const [textNew, setTextNew] = React.useState('');
  const [textModalNew, setTextModalNew] = React.useState(false);
  const handleOpenTextModalNew = () => setTextModalNew(true);
  const handleCloseTextModalNew = () => setTextModalNew(false);
  const handleTextChangeNew = (event) => {
    setTextNew(event.target.value);
  };
  const handleAddTextNew = () => {
    if (textNew.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      setPreviewData([...previewData, { name: textNew, type: 'text' }]);
      setErrorMessage('');
      setTextNew('');
      handleCloseTextModalNew();
    }
  };
  //  image
  const [imageModal, setImageModal] = React.useState(false);
  const handleOpenImageModal = () => setImageModal(true);
  const handleCloseImageModal = () => setImageModal(false);
  const [imageData, setimageData] = React.useState({ solutionImage: null });
  const handelImageChange = (e) => {
    const { name, files } = e.target;
    setimageData({
      ...imageData,
      [name]: name === 'solutionImage' ? files[0] : e.target.value,
    });
  };

  const handleAddImage = () => {
    if (imageData?.solutionImage) {
      // Replace the existing image in the previewData array
      const updatedPreviewData = [...previewData];
      updatedPreviewData[editingIndex] = {
        name: imageData?.solutionImage,
        type: 'image',
      };
      setPreviewData(updatedPreviewData);

      // Clear the imageData state
      setimageData({ solutionImage: null });
    }
    handleCloseImageModal();
  };

  const [imageModalNew, setImageModalNew] = React.useState(false);
  const handleOpenImageModalNew = () => setImageModalNew(true);
  const handleCloseImageModalNew = () => setImageModalNew(false);
  const [imageDataNew, setimageDataNew] = React.useState({
    solutionImageNew: null,
  });
  const handelImageChangeNew = (e) => {
    const { name, files } = e.target;
    setimageDataNew({
      ...imageDataNew,
      [name]: name === 'solutionImageNew' ? files[0] : e.target.value,
    });
  };

  const handleAddImageNew = () => {
    if (imageDataNew?.solutionImageNew) {
      setPreviewData([...previewData, { name: imageDataNew?.solutionImageNew, type: 'image' }]);
    }
    setimageDataNew(null);
    handleCloseImageModalNew();
  };

  //  pdf file
  const [PDFModal, setPDFModal] = React.useState(false);
  const handleOpenPDFModal = () => setPDFModal(true);
  const handleClosePDFModal = () => setPDFModal(false);
  const [PDFData, setPDFData] = React.useState({ solutionPDF: null });
  const handelPDFChange = (e) => {
    const { name, files } = e.target;
    setPDFData({
      ...PDFData,
      [name]: name === 'solutionPDF' ? files[0] : e.target.value,
    });
  };

  const handleAddPDF = () => {
    if (PDFData?.solutionPDF) {
      // Replace the existing image in the previewData array
      const updatedPreviewData = [...previewData];
      updatedPreviewData[editingIndex] = {
        name: PDFData?.solutionPDF,
        type: 'link',
      };
      setPreviewData(updatedPreviewData);

      // Clear the imageData state
      setPDFData({ solutionPDF: null });
    }
    handleClosePDFModal();
  };

  const [PDFModalNew, setPDFModalNew] = React.useState(false);
  const handleOpenPDFModalNew = () => setPDFModalNew(true);
  const handleClosePDFModalNew = () => setPDFModalNew(false);
  const [PDFDataNew, setPDFDataNew] = React.useState({
    solutionPDFNew: null,
  });

  const handelPDFChangeNew = (e) => {
    const { name, files } = e.target;
    setPDFDataNew({
      ...PDFDataNew,
      [name]: name === 'solutionPDFNew' ? files[0] : e.target.value,
    });
  };

  const handleAddPDFNew = () => {
    if (PDFDataNew?.solutionPDFNew) {
      setPreviewData([...previewData, { name: PDFDataNew?.solutionPDFNew, type: 'link' }]);
    }
    setPDFDataNew(null);
    handleClosePDFModalNew();
  };

  // video
  const [videoModal, setVideoModal] = React.useState(false);
  const handleOpenVideoModal = () => setVideoModal(true);
  const handleCloseVideoModal = () => setVideoModal(false);
  const [youtubeVideo, setYoutubeVideo] = React.useState('');
  const [videoData, setVideoData] = React.useState({ solutionVideo: null });
  const handleYoutubeChange = (event) => {
    setYoutubeVideo(event.target.value);
  };
  const handelVideoChange = (e) => {
    const { name, files } = e.target;

    setVideoData({
      ...videoData,
      [name]: name === 'solutionVideo' ? files[0] : e.target.value,
    });
  };
  const handleAddVideo = () => {
    if (youtubeVideo.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // setPreviewData([...previewData, { name: subTitle, type: 'subTitle' }]);
      // setErrorMessage('');
      // setSubTitle('');
      // handleCloseSubTitleModal();
      // const existingTitleIndex = previewData.findIndex((item) => item.type === 'subTitle');

      if (editingIndex !== -1) {
        const updatedPreviewData = [...previewData];
        updatedPreviewData[editingIndex] = {
          name: youtubeVideo,
          type: 'youTube',
        };
        setPreviewData(updatedPreviewData);
      } else {
        setPreviewData([...previewData, { name: youtubeVideo, type: 'youTube' }]);
      }
      setYoutubeVideo('');
      setErrorMessage('');
      handleCloseVideoModal();
      handleFirstButtonClick();
      setEditingIndex(null);
      setEditingType(null);
    }
  };

  const [videoModalNew, setVideoModalNew] = React.useState(false);
  const handleOpenVideoModalNew = () => setVideoModalNew(true);
  const handleCloseVideoModalNew = () => setVideoModalNew(false);
  const [youtubeVideoNew, setYoutubeVideoNew] = React.useState('');

  const handleYoutubeChangeNew = (event) => {
    setYoutubeVideoNew(event.target.value);
  };
  // const handelVideoChange = (e) => {
  //   const { name, files } = e.target;

  //   setVideoData({
  //     ...videoData,
  //     [name]: name === 'solutionVideo' ? files[0] : e.target.value,
  //   });
  // };
  const handleAddVideoNew = () => {
    if (youtubeVideoNew.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      setPreviewData([...previewData, { name: youtubeVideoNew, type: 'youTube' }]);
      setErrorMessage('');
      setYoutubeVideoNew('');
      handleCloseVideoModalNew();
    }
  };

  const handleDeleteItem = (index) => {
    const updatedPreviewData = [...previewData];
    updatedPreviewData.splice(index, 1);
    setPreviewData(updatedPreviewData);
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      // Ensure category is provided
      if (!category) {
        throw new Error('Category is required');
      }

      console.log("aa",previewData)
      const processedItems = await Promise.all(
        previewData.map(async (item) => {
          let newItem;
          if (item.name instanceof File) {

            const headers = {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            };
            // If the item is a file, upload it using the upload API
            const data = new FormData();
            data.append('image', item.name);
  
            const response = await uploadFile(data , headers); // Assuming uploadFile returns a promise that resolves with the response
            newItem = {...item, name: response.data }; // Update the item's text with the response from the upload API
          } else {
            // For other types, just copy the item as is
            newItem = item;
          }
          return newItem;
        })
      );

      const data = {
        title: displayedTitle,
        avatar: imageLogoDisplayed?.name,
        solutions: processedItems,
      };

      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await createSolution(category, data, headers);

      await Swal.fire({
        icon: 'success',
        title: 'Solution Added successfully',
      }).then(() => {
        navigate(-1);
      });

      setLoading(false);
    } catch (error) {
      console.error('Error adding solution:', error.response ? error.response.data : error.message);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Add Solution | Page </title>
      </Helmet>

      <Card sx={{ padding: 2 }}>
        <Stack onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mb: 3, mt: 2 }}>
          <FaArrowLeftLong size={24} />
        </Stack>

        <Container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Stack sx={{ width: '40%' }}>
            <Typography alignSelf={'center'} variant="h4">
              Add Solution
            </Typography>

            <Stack
              spacing={4}
              sx={{
                height: '80vh',
                border: 1,
                borderColor: 'grey.400',
                padding: 2,
                borderRadius: 2,
                alignItems: 'center',
              }}
            >
              {/* solutionj image logo start */}
              <Button
                disabled={isDisableavatar}
                onClick={handleOpenImageLogoModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Solution Avatar
              </Button>

              <Modal
                open={imageLogoModal}
                onClose={handleCloseImageLogoModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Image
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {imageLogoData?.solutionImageLogo && (
                        <img
                          src={URL.createObjectURL(imageLogoData?.solutionImageLogo)}
                          alt="img"
                          style={{ maxWidth: '250px', borderRadius: 10 }}
                        />
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Image
                      <input
                        type="file"
                        accept="image/*"
                        name="solutionImageLogo"
                        style={{ display: 'none' }}
                        onChange={handelImageLogoChange}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddImageLogo}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Image
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              <Modal
                open={imageLogoModalNew}
                onClose={handleCloseImageLogoModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Image
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {imageLogoDataNew?.solutionImageLogoNew && (
                        <img
                          src={URL.createObjectURL(imageLogoDataNew?.solutionImageLogoNew)}
                          alt="img"
                          style={{ maxWidth: '250px', borderRadius: 10 }}
                        />
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Image
                      <input
                        type="file"
                        accept="image/*"
                        name="solutionImageLogoNew"
                        style={{ display: 'none' }}
                        onChange={handelImageLogoChangeNew}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddImageLogoNew}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Image
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              {/* solutionj image logo end */}
              <Button
                disabled={isDisable}
                onClick={handleOpenTitleModal}
                sx={{
                  width: 200,
                  height: 50,

                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Title
              </Button>

              {/* title modal */}
              <Modal
                open={titleModal}
                onClose={handleCloseTitleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Title
                  </Typography>
                  <TextField
                    sx={{
                      mt: 4,
                    }}
                    fullWidth
                    multiline
                    rows={3}
                    id="outlined-basic"
                    label="Enter Title"
                    variant="outlined"
                    value={title}
                    onChange={handleTitleChange}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddTitle}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Title
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>
              <Button
                disabled={!isDisable}
                onClick={handleOpenSubTitleModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Subtitle
              </Button>
              {/* subtitle modal */}
              <Modal
                open={subTitleModal}
                onClose={handleCloseSubTitleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Sub Title
                  </Typography>
                  <TextField
                    sx={{
                      mt: 4,
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Subtitle"
                    variant="outlined"
                    value={subTitle}
                    onChange={handlesubTitleChange}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddsubTitle}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Subtitle
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>
              <Modal
                open={subTitleModalNew}
                onClose={handleCloseSubTitleModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Sub Title
                  </Typography>
                  <TextField
                    sx={{
                      mt: 4,
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Subtitle"
                    variant="outlined"
                    onChange={handlesubTitleChangeNew}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddsubTitleNew}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Subtitle
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>
              <Button
                disabled={!isDisable}
                onClick={handleOpenTextModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Text
              </Button>
              {/* Text modal */}
              <Modal
                open={textModal}
                onClose={handleCloseTextModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Text
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      mt: 4,
                    }}
                    id="outlined-multiline-static"
                    label="Enter text..."
                    multiline
                    rows={5}
                    value={text}
                    onChange={handleTextChange}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddText}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Text
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>
              <Modal
                open={textModalNew}
                onClose={handleCloseTextModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Text
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      mt: 4,
                    }}
                    id="outlined-multiline-static"
                    label="Enter text..."
                    multiline
                    rows={5}
                    onChange={handleTextChangeNew}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddTextNew}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Text
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>

              {/* image start */}
              <Button
                disabled={!isDisable}
                onClick={handleOpenImageModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Image
              </Button>
              {/* Image modal */}
              <Modal
                open={imageModal}
                onClose={handleCloseImageModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Image
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {imageData?.solutionImage && (
                        <img
                          src={URL.createObjectURL(imageData?.solutionImage)}
                          alt="img"
                          style={{ maxWidth: '250px', borderRadius: 10 }}
                        />
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Image
                      <input
                        type="file"
                        accept="image/*"
                        name="solutionImage"
                        style={{ display: 'none' }}
                        onChange={handelImageChange}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddImage}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Image
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              <Modal
                open={imageModalNew}
                onClose={handleCloseImageModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Image
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {imageDataNew?.solutionImageNew && (
                        <img
                          src={URL.createObjectURL(imageDataNew?.solutionImageNew)}
                          alt="img"
                          style={{ maxWidth: '250px', borderRadius: 10 }}
                        />
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Image
                      <input
                        type="file"
                        accept="image/*"
                        name="solutionImageNew"
                        style={{ display: 'none' }}
                        onChange={handelImageChangeNew}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddImageNew}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Image
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              {/* image end */}

              {/* pdf file start */}

              <Button
                disabled={!isDisable}
                onClick={handleOpenPDFModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Pdf File
              </Button>

              <Modal
                open={PDFModal}
                onClose={handleClosePDFModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    PDF
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {PDFData?.solutionPDF && (
                        <Typography color="red" id="modal-modal-title" variant="h4" component="h2">
                          {PDFData?.solutionPDF?.name}
                        </Typography>
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload PDF
                      <input
                        type="file"
                        accept="application/pdf"
                        name="solutionPDF"
                        style={{ display: 'none' }}
                        onChange={handelPDFChange}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddPDF}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add PDF
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              <Modal
                open={PDFModalNew}
                onClose={handleClosePDFModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    PDF
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {PDFDataNew?.solutionPDFNew && (
                        <Typography color="red" id="modal-modal-title" variant="h4" component="h2">
                          {PDFDataNew?.solutionPDFNew?.name}
                        </Typography>
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload PDF
                      <input
                        type="file"
                        accept="application/pdf"
                        name="solutionPDFNew"
                        style={{ display: 'none' }}
                        onChange={handelPDFChangeNew}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddPDFNew}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add PDF
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              {/* pdf file end */}

              <Button
                disabled={!isDisable}
                onClick={handleOpenVideoModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Video
              </Button>
              {/* Video modal */}
              <Modal
                open={videoModal}
                onClose={handleCloseVideoModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Video
                  </Typography>
                  {/* <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {videoData?.solutionVideo && (
                        <Stack sx={{ mt: 2, width: 300 }}>
                          <Player playsInline src={URL.createObjectURL(videoData?.solutionVideo)} />
                        </Stack>
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Video
                      <input
                        type="file"
                        accept="video/mp4"
                        name="solutionVideo"
                        style={{ display: 'none' }}
                        onChange={handelVideoChange}
                      />
                    </Button>
                  </Stack>
                  <Divider sx={{ mt: 2, mb: 2 }}>OR</Divider> */}
                  <TextField
                    onChange={handleYoutubeChange}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Youtube Link"
                    variant="outlined"
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddVideo}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Video
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              <Modal
                open={videoModalNew}
                onClose={handleCloseVideoModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Video
                  </Typography>
                  {/* <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {videoData?.solutionVideo && (
                        <Stack sx={{ mt: 2, width: 300 }}>
                          <Player playsInline src={URL.createObjectURL(videoData?.solutionVideo)} />
                        </Stack>
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Video
                      <input
                        type="file"
                        accept="video/mp4"
                        name="solutionVideo"
                        style={{ display: 'none' }}
                        onChange={handelVideoChange}
                      />
                    </Button>
                  </Stack>
                  <Divider sx={{ mt: 2, mb: 2 }}>OR</Divider> */}
                  <TextField
                    onChange={handleYoutubeChangeNew}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Youtube Link"
                    variant="outlined"
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddVideoNew}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Video
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              {/* pdf file link */}

              {/* <Button
                disabled={!isDisable}
                onClick={handleOpenLinkModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add pdf file link
              </Button> */}
              {/* pdf file modal modal */}
              {/* <Modal
                open={linkModal}
                onClose={handleCloseLinkModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Pdf File Link
                  </Typography>
                  <TextField
                    sx={{
                      mt: 4,
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Link"
                    variant="outlined"
                    value={link}
                    onChange={handleLinkChange}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddLink}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Link
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal> */}
              {/* <Modal
                open={linkModalNew}
                onClose={handleCloseLinkModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Pdf File Link
                  </Typography>
                  <TextField
                    sx={{
                      mt: 4,
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Link"
                    variant="outlined"
                    onChange={handleLinkChangeNew}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddLinkNew}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Link
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal> */}

              <FormControl sx={{ m: 1, width: 200 }}>
                <InputLabel id="demo-simple-select-autowidth-label">Select Category</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={category}
                  onChange={handleChange}
                  autoWidth
                  label="Select Category"
                >
                  {categories.map((item, i) => {
                    return <MenuItem value={item?._id}>{item?.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Stack sx={{ width: '40%' }}>
            <Typography alignSelf={'center'} variant="h4">
              Preview
            </Typography>
            <Stack
              // spacing={1}
              sx={{
                height: '80vh',
                border: 1,
                borderColor: 'grey.400',
                padding: 3,
                borderRadius: 2,
                overflow: 'auto',
              }}
            >
              {previewData.map((item, index) => {
                if (item.type === 'avatar') {
                  return (
                    <Box key={index} alignSelf="center" mt={3}>
                      <Avatar
                        alt="solution logo img"
                        src={URL.createObjectURL(item?.name)}
                        sx={{ width: 40, height: 40 }}
                      />
                      {item.type !== 'title' && item.type !== 'avatar' && (
                        <MdDeleteOutline
                          cursor="pointer"
                          color="red"
                          size={20}
                          onClick={() => handleDeleteItem(index)}
                        />
                      )}
                      <CiEdit color="green" cursor="pointer" size={20} onClick={() => handleEditItem(index)} />
                    </Box>
                  );
                }
                return null;
              })}
              {previewData.map((item, index) => {
                if (item.type !== 'avatar') {
                  return (
                    <div key={index}>
                      {item.type === 'title' && <Typography variant="subtitle2">{item.name}</Typography>}
                      {item.type === 'subTitle' && <Typography variant="subtitle1">{item.name}</Typography>}
                      {/* {item.type === 'link' && (
                        <Typography color={'#2196f3'} variant="subtitle1">
                          {item.name}
                        </Typography>
                      )} */}
                      {item.type === 'text' && (
                        <Typography spacing={1} sx={{ fontSize: 13 }}>
                          {item.name}
                        </Typography>
                      )}
                      {item.type === 'image' && (
                        <Box key={index} alignSelf="center" mt={3}>
                          <Card variant="outlined" sx={{ borderRadius: 2 }}>
                            <img
                              style={{
                                width: '100%',
                                height: '200px',
                                objectFit: 'cover',
                              }}
                              src={URL.createObjectURL(item?.name)}
                              alt="img"
                            />
                          </Card>
                        </Box>
                      )}
                      {/* {item.type === 'link' && (
                        <Box key={index} alignSelf="center" mt={2}>
                          <Card  variant="outlined" sx={{ borderRadius: 1,padding:2,backgroundColor:"red" }}>
                            <Typography sx={{color:"white"}} id="modal-modal-title" variant="h6" component="h2">
                              {item?.name?.name}
                            </Typography>
                          </Card>
                        </Box>
                      )} */}
                      {item.type === 'link' && (
                        <Box key={index} alignSelf="center" mt={2}>
                          <Card variant="outlined">
                            <embed
                              src={URL.createObjectURL(item?.name)}
                              width="100%"
                              height="400px"
                              type="application/pdf"
                            />
                          </Card>
                        </Box>
                      )}
                      {item.type === 'video' && (
                        <Stack>
                          <Card variant="outlined" sx={{ borderRadius: 2, mt: 3 }}>
                            {/* Video rendering logic */}
                          </Card>
                        </Stack>
                      )}
                      {item.type === 'youTube' && (
                        <Stack>
                          <Card variant="outlined" sx={{ borderRadius: 2, mt: 3 }}>
                            <YoutubeEmbed videoUrl={item?.name} />
                          </Card>
                        </Stack>
                      )}
                      {item.type !== 'title' && item.type !== 'avatar' && (
                        <MdDeleteOutline
                          cursor="pointer"
                          color="red"
                          size={20}
                          onClick={() => handleDeleteItem(index)}
                        />
                      )}
                      <CiEdit color="green" cursor="pointer" size={20} onClick={() => handleEditItem(index)} />
                    </div>
                  );
                }
                return null;
              })}
            </Stack>
          </Stack>
        </Container>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 6,
          }}
        >
          <LoadingButton
            onClick={handleSave}
            loading={loading}
            sx={{
              width: 200,
              height: 50,
              boxShadow: 'none',
              backgroundColor: '#e50000',

              '&:hover': {
                backgroundColor: '#AC0000',
                boxShadow: 'none',
              },
            }}
            variant="contained"
          >
            Save Solution
          </LoadingButton>
        </Stack>
      </Card>
    </>
  );
};

export default AddSolution;
