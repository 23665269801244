import { Button, Card, Container, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { createAnnouncement, getAnnouncement } from '../helper/backend_helper';

const AnnouncementPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const Announcement = async () => {
    try {
      const announcementResponse = await getAnnouncement();
      setData(announcementResponse?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Announcement();
  }, []);
  useEffect(() => {
    setAnnouncemet(data?.title || '');
  }, [data]);
  const [announcemet, setAnnouncemet] = useState('');

  const [loading, setLoading] = useState(false);
  const hnadleClick = async () => {
    try {
      setLoading(true);

      // Check if any field is empty
      if (!announcemet) {
        Swal.fire({
          icon: 'error',
          title: 'error',
          text: 'Please fill in all fields!',
        });
        setLoading(false);
        return;
      }

      const data = {
        title: announcemet,
      };

      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await createAnnouncement(data, headers);
    

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Announcement sent successfully',
      });

      // setAnnouncemet('');

      setLoading(false);
    } catch (error) {
      console.error('Error creating user:', error.response.data.errors[0].detail);
      Swal.fire({
        icon: 'error',
        title: 'error',
        text: `${error.response.data.errors[0].detail}`,
      });
      setLoading(false);
      // Handle error, maybe show an error message to the user
    }
  };

  return (
    <>
      <Helmet>
        <title> Announcement</title>
      </Helmet>
      <Card sx={{ padding: 1, height: '100vh' }}>
        <Container>
          <Typography variant="h4">Announcement</Typography>

          <Stack marginTop={3}>
            <TextField
              value={announcemet}
              onChange={(e) => setAnnouncemet(e.target.value)}
              id="outlined-multiline-static"
              label="Enter text..."
              multiline
              rows={5}
            />
          </Stack>
          <LoadingButton
            onClick={hnadleClick}
            loading={loading}
            sx={{
              mt: 4,
              mb: 4,
              boxShadow: 'none',
              backgroundColor: '#FF0000',

              '&:hover': {
                backgroundColor: '#e50000',
                boxShadow: 'none',
              },
            }}
            variant="contained"
          >
            Send Announcement
          </LoadingButton>
        </Container>
      </Card>
    </>
  );
};

export default AnnouncementPage;
