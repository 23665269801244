import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// Login Method
 export const postJwtLogin = (data, headers) => api.create(url.POST_LOGIN, data, headers);

export const getUsers = () => api.get(url.GET_USERS);
export const getSolutions = () => api.get(url.GET_SOLUTIONS);
export const getAnnouncement = () => api.get(url.GET_ANNOUNCEMENT);

export const createAnnouncement = (data , headers) => api.create(url.GET_ANNOUNCEMENT,data,headers);
export const getCategories = () => api.get(url.GET_CATEGORIES);
export const createCategory = (data , headers) => api.create(url.GET_CATEGORIES,data,headers);
export const deleteCategory = (id, headers) =>
  api.create(`${url.DELETE_CATEGORY}/${id}`, {}, headers);
  export const updateCategory = (id, data, headers) => {
    return api.create(`${url.UPDATE_CATEGORY}/${id}`, data, headers);
  }
  export const createSolution = (id, data, headers) => {
    return api.create(`${url.CREATE_SOLUTION}/${id}`, data, headers);
  }

export const createUser = (data , headers) => api.create(url.CREATE_USER,data,headers);

export const deleteUser = (id, headers) =>
  api.create(`${url.DELETE_USER}/${id}`, {}, headers);

  export const updateUser = (id, data, headers) => {
    return api.create(`${url.UPDATE_USER}/${id}`, data, headers);
  }

  export const uploadFile = (data , headers) => api.create(url.UPLOAD_FILE,data,headers);
  export const deleteSolution = (id, headers) =>
  api.create(`${url.DELETE_SOLUTION}/${id}`, {}, headers);



  export const updateSolution = (id, data, headers) => {
    return api.update(`${url.CREATE_SOLUTION}/${id}`, data, headers);
  }


  export const getBanner = () => api.get(url.GET_BANNER);
  export const createBanner = (data , headers) => api.create(url.GET_BANNER,data,headers);

  export const deleteUploads = (data , headers) => api.create(url.DELETE_UPLOADS,data,headers);


  // ===================================================================================



  export const getBookings = () => api.get(url.GET_BOOKINGS);
  export const getBnbList = () => api.get(url.GET_BNBLIST);

  export const createBnBList = (data , headers) => api.create(url.GET_BNBLIST,data,headers)


  export const updateBnbList = (id, data, headers) => {
    return api.create(`${url.GET_BNBLIST}/${id}`, data, headers);
  }



  export const getDoorList = () => api.get(url.GET_DOORLIST);

  export const createDoorList = (data , headers) => api.create(url.CREATE_DOORLIST,data,headers)



  export const getContactInfo = () => api.get(url.CONTACTINFO);

  export const createContactInfo = (data , headers) => api.create(url.CONTACTINFO,data,headers)


  export const getAllQuestions = () => api.get(url.QUESTIONS);

  export const createQuestion = (data , headers) => api.create(url.QUESTIONS,data,headers)


  export const updateAuthToken = (data , headers) => api.create(url.AUTH_TOKEN,data,headers)