import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';

import axios from 'axios';
import appConfig from '../../config';
import { createUser, updateBnbList, updateUser } from '../../helper/backend_helper';
import Iconify from '../iconify';

const UpdateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.userData;


  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    wifiPassword: '',
    wifiSsid: '',
    contactNo: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      // Check if any field is empty
      if (!formData.wifiPassword || !formData.wifiSsid || !formData.contactNo ) {
        Swal.fire({
          icon: 'error',
          title: 'error',
          text: 'Please fill in all fields!',
        });
        setLoading(false);
        return; // Stop execution if any field is empty
      }
    
      const data = {
        contactNumber:formData.contactNo,
        wifiPassword:formData.wifiPassword,
        wifiSSID:formData.wifiSsid
      };

      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateBnbList(id, data, headers);
    

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'BnB List updated successfully',
      });

      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'error',
        text: `SomeThing went Wrong`,
      });
      setLoading(false);
      // Handle error, maybe show an error message to the user
    }
  };

  return (
    <>
      <Helmet>
        <title>Update bnb </title>
      </Helmet>
      <Card sx={{ padding: 1 }}>
        <Container>
          <Stack
            onClick={() => navigate(-1)}
            gap={3}
            sx={{ cursor: 'pointer', mb: 3 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <FaArrowLeftLong size={24} />
            <Typography variant="h4">Update BnB List</Typography>
          </Stack>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '40ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
                <FormLabel sx={{ mb: 1 }}>Wifi SSID</FormLabel>
                <TextField
                  name="wifiSsid"
                  label="Wifi SSID"
                  value={formData.wifiSsid}
                  onChange={handleInputChange}
                />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Wifi Password</FormLabel>
                <TextField
                  name="wifiPassword"
                  label="Wifi Password"
                  value={formData.wifiPassword}
                  onChange={handleInputChange}
                />
              </Stack>

              <Stack>
                <FormLabel sx={{ mb: 1 }}>Contact No</FormLabel>
                <TextField
                type='number'
                  name="contactNo"
                  label="Contact No"
                  value={formData.contactNo}
                  onChange={handleInputChange}
                />
              </Stack>
            </Stack>

            <LoadingButton
              sx={{
                width: 150,
                mt: 4,
                mb: 4,
                boxShadow: 'none',
                backgroundColor: '#3A5239',

                '&:hover': {
                  backgroundColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
              onClick={handleSave}
              loading={loading}
            >
              Update
            </LoadingButton>
          </Box>
        </Container>
      </Card>
    </>
  );
};

export default UpdateUser;
