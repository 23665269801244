import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

import DailyGuidePage from './pages/DailyGuidePage';
import NotificationPage from './pages/NotificationPage';
import DashboardAppPage from './pages/DashboardAppPage';








import ContactUsPage from './pages/ContactUsPage';
import ContactUsDetail from './components/contactus/ContactUsDetail';
import LogoutPage from './pages/LogoutPage';
import ChatPage from './pages/ChatPage';
import ContractorPositionPage from './pages/ContractorPositionPage';
import CreatePosition from './components/constractorPosition/CreatePosition';
import AddUser from './components/user/addUser';
import AddSolutionPage from './pages/AddSolutionPage';
import AddSolution from './components/addSolution/addSolution';
import AnnouncementPage from './pages/AnnouncementPage';
import Categories from './pages/Categories';
import AddCategories from './components/category/AddCategories';
import UpdateUser from './components/user/updateUser';
import UpdateCategory from './components/category/UpdateCategory';
import UpdateSolution from './components/addSolution/updateSolution';

import Banner from './pages/Banner';
import FAQs from './pages/FAQsPage';
import AddFAQs from "./components/addFaqs/AddFAQs"
import AuthToken from "./pages/authTokenPage"



// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'bnbList', element: <UserPage /> },
        { path: 'processBookings', element: <AddSolutionPage /> },
        { path: 'announcement', element: <AnnouncementPage /> },
        { path: 'webappSettings', element: <Banner /> },
        { path: 'doorList', element: <Categories /> },
        { path: 'chat', element: <ChatPage /> },
        { path: 'notifications', element: <NotificationPage /> },
        { path: 'contact-us', element: <ContactUsPage /> },
        { path: 'new-invoice', element: <NotificationPage /> },
        { path: 'approved-invoice', element: <BlogPage /> },
        { path: 'logout', element: <LogoutPage /> },
        { path: 'contractor-positions', element: <ContractorPositionPage /> },
        { path: 'faqs', element: <FAQs /> },
        { path: 'authToken', element: <AuthToken /> },
        // user routes

        // { path: 'user-detail', element: <UserDetail /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // jobs routes

        // Active jobs routes

        // Complete jobs Page
        // { path: 'complete-job-Detail', element: <CompleteJobDetail/> },

        // contact us route
      ],
    },
    {
      path: '/bnbList',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        { path: 'add_bnbList', element: <AddUser /> },
        { path: 'update_bnbList', element: <UpdateUser/> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },
    {
      path: '/add_solution',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_newsolution', element: <AddSolution /> },
         { path: 'update_solution', element: <UpdateSolution /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
      ],
    },
    {
      path: '/announcement',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        // { path: 'create-job', element: <CreateJob /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
      ],
    },
    {
      path: '/doorList',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_doorList', element: <AddCategories /> },
         { path: 'update-category', element: <UpdateCategory /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
        // { path: 'complete-job-Detail', element: <CompleteJobDetail/> },
      ],
    },
    {
      path: '/faqs',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
         { path: 'add_faqs', element: <AddFAQs /> },
         { path: 'update-category', element: <UpdateCategory /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
        // { path: 'complete-job-Detail', element: <CompleteJobDetail/> },
      ],
    },

    {
      path: '/webappSettings',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        // { path: 'create-job', element: <CreateJob /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
      ],
    },

    {
      path: '/contact-us',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        // { path: 'create-job', element: <CreateJob /> },
        // { path: 'job-Detail', element: <JobHistory /> },
        // { path: 'all-bids', element: <Index /> },
        // { path: 'contractor-detail', element: <UserDetail /> },
        // { path: 'attendenceDetail', element: <AttendenceDetail /> },
        // { path: 'UiDesigner', element: <UiDesigner /> },
        // { path: 'active-job', element: <ActiveJob/> },
        // { path: 'complete-job-Detail', element: <CompleteJobDetail/> },
        // { path: 'contactUs-Detail', element: <ContactUsDetail/> },
      ],
    },
    {
      path: '/contractor-positions',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/user-detail" />, index: true },
        { path: 'create-position', element: <CreatePosition /> },
      ],
    },

    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
