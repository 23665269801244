const appConfig = {
  // API_URL: 'http://localhost:5000/api/v1',
  // SITE_URL:"http://localhost:5173",
  // FILE_URL: 'http://localhost:5000/uploads/',

  API_URL: 'https://backend.timelesspuglia.com/api/v1',
  SITE_URL:"https://app.timelesspuglia.com",
  FILE_URL: 'https://backend.timelesspuglia.com/uploads/',
};



export default appConfig;
