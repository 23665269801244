// component
import SvgColor from '../../../components/svg-color';
import pls from '../../../assets/PLS.png';
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------
const icon = (name) => (
  <img
    src={`/assets/icons/navbar/${name}.svg`}
    alt={name}
    style={{ width: '22px', height: '22px' }}
  />
);
// const icon = (name) => <SvgColor src={`http://93.188.167.72/moodlift-admin/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/app',
    //  icon: icon('ic_analytics'),
    icon: <Iconify icon={'eva:grid-fill'} width={20} sx={{ cursor: 'pointer' }} />,
  },
 
  {
    title: 'Process Bookings',
    path: '/processBookings',
    // icon: icon('ic_user'),
    icon: <Iconify icon={'eva:plus-square-fill'} width={20} sx={{ cursor: 'pointer' }} />,
  },
  {
    title: 'BnB List',
    path: '/bnbList',
    // icon: icon('ic_user'),
    icon: <Iconify icon={'mdi-view-grid-plus'} width={20} sx={{ cursor: 'pointer' }} />,
  },
  // {
  //   title: 'Announcement',
  //   path: '/announcement',
  //   // icon: icon('ic_notification_package'),
  //   icon: <Iconify icon={'mdi-bullhorn'} width={20} sx={{ cursor: 'pointer' }} />,
  // },
  {
    title: 'Door List',
    path: '/doorList',
    // icon: icon('ic_notification_package'),
    icon: <Iconify icon={'mdi-bullhorn'} width={20} sx={{ cursor: 'pointer' }} />,
  },
  {
    title: 'WebApp Settings',
    path: '/webappSettings',
    // icon: icon('ic_notification_package'),
    icon: <Iconify icon={'eva:phone-call-fill'} width={20} sx={{ cursor: 'pointer' }} />,
  },
  {
    title: 'FAQs',
    path: '/faqs',
    // icon: icon('ic_notification_package'),
    icon: <Iconify icon={'eva:question-mark-circle-outline'} width={20} sx={{ cursor: 'pointer' }} />,
  },
  {
    title: 'Auth Token',
    path: '/authToken',
    // icon: icon('ic_notification_package'),
    icon: <Iconify icon={'eva:message-square-fill'} width={20} sx={{ cursor: 'pointer' }} />,
  },
  // {
  //   title: 'Notifications',
  //   path: '/notifications',
  //   // icon: icon('ic_notification_package'),
  //   icon: <Iconify icon={'eva:bell-fill'} width={20} sx={{ cursor: 'pointer' }} />,
  // },
  // {
  //   title: 'Contact us',
  //   path: '/contact-us',
  //   // icon: icon('ic_notification_package'),
  //   icon: <Iconify icon={'eva:phone-call-fill'} width={20} sx={{ cursor: 'pointer' }} />,
  // },
  // {
  //   title: 'Contractor Positions',
  //   path: '/contractor-positions',
  //   // icon: icon('ic_notification_package'),
  //   icon: <Iconify icon={'eva:plus-square-fill'} width={20} sx={{ cursor: 'pointer' }} />,
  // },

  {
    title: 'Log out',
    path: '/logout',
    // icon: icon('ic_notification_package'),
    icon: <Iconify icon={'eva:log-out-fill'} width={20} sx={{ cursor: 'pointer' }} />,
  },
];

export default navConfig;
