import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function LogoutPage() {
    const navigate = useNavigate();
        useEffect(()=>{
            handleLogout()
        },[])
     const handleLogout = () => {
    localStorage.removeItem('technisches');
     navigate('/login', { replace: true });
  };
  return (
    <></>
  )
}

export default LogoutPage
