export const POST_LOGIN = '/auth/login';

export const GET_USERS = '/user'
export const CREATE_USER = '/user/create'
export const DELETE_USER = '/user/delete'
export const UPDATE_USER = '/user/update'
export const GET_SOLUTIONS = '/solution/get-all-solutions'
export const GET_ANNOUNCEMENT = '/announcement'
export const GET_CATEGORIES = '/category'
export const DELETE_CATEGORY = '/category/delete'
export const UPDATE_CATEGORY = '/category/update'
export const CREATE_SOLUTION = '/solution'
export const UPLOAD_FILE = '/upload'
export const DELETE_SOLUTION = '/solution/delete'
export const GET_BANNER = "/banner"
export const DELETE_UPLOADS = "/upload/delete"




// ============================================================================

export const GET_BOOKINGS = '/processBooking'
export const GET_BNBLIST  = "/bnbList"
export const GET_DOORLIST  = "/doorList"
export const CREATE_DOORLIST  = "/doorList/create"

export const CONTACTINFO  = "/contactInfo"

export const QUESTIONS  = "/question"
export const AUTH_TOKEN = "/authToken"