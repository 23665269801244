import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
  Avatar,
  Tooltip,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { Player } from 'video-react';

import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { SlCloudUpload } from 'react-icons/sl';
import { MdDeleteOutline } from 'react-icons/md';
import { CiEdit } from 'react-icons/ci';
import { IoMdAdd } from 'react-icons/io';
import appConfig from '../../config';
import image from '../../assets/Group.png';
import video from '../../assets/video.mp4';
import { createSolution, deleteUploads, getCategories, updateSolution, uploadFile } from '../../helper/backend_helper';
import YoutubeEmbed from './youTube';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  // height: 400,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const UpdateSolution = () => {
  const navigate = useNavigate();
  const [nextIndex, setNextIndex] = React.useState(undefined);
  const [openAddNewModal, setOpenAddNewModal] = React.useState(false);
  const handleOpenAddNewModal = (index) => {
    setNextIndex(index);
    setOpenAddNewModal(true);
  };

  const handleCloseAddNewModal = () => setOpenAddNewModal(false);

  const location = useLocation();
  const Data = location?.state?.solutionData;

  const [previewData, setPreviewData] = React.useState(Data?.solutions);
  const [deletedItems , setDeletedItems] = useState([])
 
const [deletedEditItems , setDeletedEditItems] = useState({})

 
  const [editingIndex, setEditingIndex] = React.useState(null);
  const [editingType, setEditingType] = React.useState(null);

  
  const handleEditItem = (index,item) => {
    setDeletedEditItems(item)
    setEditingIndex(index);

    setEditingType(previewData[index].type);
    switch (previewData[index].type) {
      case 'title':
        handleOpenTitleModal();
        break;
      case 'subTitle':
        setSubTitle(previewData[index]?.text);
        handleOpenSubTitleModal();
        break;

      // case 'link':
      //   setLink(previewData[index]?.text);
      //   handleOpenLinkModal();
      //   break;
      case 'text':
        setText(previewData[index]?.text);
        handleOpenTextModal();
        break;
      case 'avatar':
        setimageLogoData({ solutionImageLogo: previewData[index].text });
        handleOpenImageLogoModal();
        break;
      case 'image':
        setimageData({ solutionImage: previewData[index].text });
        handleOpenImageModal();
        break;
      case 'link':
        setPDFData({ solutionPDF: previewData[index].text });
        handleOpenPDFModal();
        break;
      case 'youTube':
        handleOpenVideoModal();
        break;
      default:
        break;
    }
  };

  const [isDisable, setIsDisable] = React.useState(false);
  const handleFirstButtonClick = () => {
    setIsDisable(true);
  };
  const [category, setCategory] = React.useState(Data?.category?._id);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const [categories, setCategories] = React.useState([]);

  async function fetchData() {
    try {
      const categoriesResponse = await getCategories();
      const filteredCategories = categoriesResponse?.data?.data;
      setCategories(filteredCategories);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  // title

  const [title, setTitle] = React.useState('');
  const [displayedTitle, setDisplayedTitle] = React.useState('');
  const [titleModal, setTitleModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleOpenTitleModal = () => {
    if (editingIndex !== null && editingType === 'title') {
      setTitle(previewData[editingIndex].text);
      setTitleModal(true);
    }
    setTitleModal(true);
  };

  // const handleOpenTitleModal = () => setTitleModal(true);
  const [loading, setLoading] = React.useState(false);
  const handleCloseTitleModal = () => setTitleModal(false);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleAddTitle = () => {
    if (title.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      const existingTitleIndex = previewData.findIndex((item) => item.type === 'title');
      if (existingTitleIndex !== -1) {
        const updatedPreviewData = [...previewData];
        updatedPreviewData[existingTitleIndex] = { text: title, type: 'title' };
        setPreviewData(updatedPreviewData);
      } else {
        setPreviewData([...previewData, { text: title, type: 'title' }]);
      }
      setDisplayedTitle(title);
      setErrorMessage('');
      handleCloseTitleModal();
      handleFirstButtonClick();
      // setTitle('');
      setEditingIndex(null);
      setEditingType(null);
    }
  };

  const handleCloseSnackbar = () => {
    setErrorMessage('');
  };

  //  solution avatar image
  const [imageLogoDisplayed, setImageLogoDisplayed] = React.useState('');
  const [imageLogoModal, setImageLogoModal] = React.useState(false);
  const handleOpenImageLogoModal = () => setImageLogoModal(true);
  const handleCloseImageLogoModal = () => setImageLogoModal(false);
  const [imageLogoData, setimageLogoData] = React.useState({ solutionImageLogo: null });

  const handelImageLogoChange = (e) => {
    const { name, files } = e.target;
    setimageLogoData({
      ...imageLogoData,
      [name]: name === 'solutionImageLogo' ? files[0] : e.target.value,
    });
  };

  const handleAddImageLogo = () => {
   
    if (imageLogoData?.solutionImageLogo) {
   
      // Replace the existing image in the previewData array
      const updatedPreviewData = [...previewData];
      updatedPreviewData[editingIndex] = { text: imageLogoData?.solutionImageLogo, type: 'avatar' };
      setPreviewData(updatedPreviewData);

      // Clear the imageData state
      setimageLogoData({ solutionImageLogo: null });
      setImageLogoDisplayed(imageLogoData?.solutionImageLogo);
    
      if((imageLogoData?.solutionImageLogo instanceof File) ){
        if(!(deletedEditItems?.text instanceof File)){
          if(deletedEditItems?.type==="avatar"){
            setDeletedItems([...deletedItems,deletedEditItems])
          }
        }
           
      }


    }
    handleCloseImageLogoModal();
  };

  const [imageLogoModalNew, setImageLogoModalNew] = React.useState(false);
  const handleOpenImageLogoModalNew = () => setImageLogoModalNew(true);
  const handleCloseImageLogoModalNew = () => setImageLogoModalNew(false);
  const [imageLogoDataNew, setimageLogoDataNew] = React.useState({ solutionImageLogoNew: null });
  const handelImageLogoChangeNew = (e) => {
    const { name, files } = e.target;
    setimageLogoDataNew({
      ...imageLogoDataNew,
      [name]: name === 'solutionImageLogoNew' ? files[0] : e.target.value,
    });
  };

  const handleAddImageLogoNew = () => {
    if (imageLogoDataNew?.solutionImageLogoNew) {
      setPreviewData([...previewData, { text: imageLogoDataNew?.solutionImageLogoNew, type: 'avatar' }]);
    }
    setimageLogoDataNew(null);
    handleCloseImageLogoModalNew();
    setImageLogoDisplayed(imageLogoDataNew?.solutionImageLogoNew);
  };

  // subtitle
  const [subTitle, setSubTitle] = React.useState('');
  const [subTitleNew, setSubTitleNew] = React.useState('');
  const [subTitleModal, setSubTitleModal] = React.useState(false);
  const [subTitleModalNew, setSubTitleModalNew] = React.useState(false);
  const handleOpenSubTitleModalNew = () => setSubTitleModalNew(true);
  const handleOpenSubTitleModal = () => {
    // if (editingIndex !== null && editingType === 'subTitle') {
    // setSubTitle(previewData[editingIndex]?.name);
    setSubTitleModal(true);
    // }
    // setSubTitleModal(true);
  };
  const handleCloseSubTitleModal = () => setSubTitleModal(false);
  const handleCloseSubTitleModalNew = () => setSubTitleModalNew(false);
  const handlesubTitleChange = (event) => {
    setSubTitle(event.target.value);
  };
  const handlesubTitleChangeNew = (event) => {
    setSubTitleNew(event.target.value);
  };
  const handleAddsubTitle = () => {
    if (subTitle.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // setPreviewData([...previewData, { name: subTitle, type: 'subTitle' }]);
      // setErrorMessage('');
      // setSubTitle('');
      // handleCloseSubTitleModal();
      // const existingTitleIndex = previewData.findIndex((item) => item.type === 'subTitle');

      if (editingIndex !== -1) {
        const updatedPreviewData = [...previewData];
        updatedPreviewData[editingIndex] = { text: subTitle, type: 'subTitle' };
        setPreviewData(updatedPreviewData);
      } else {
        setPreviewData([...previewData, { text: subTitle, type: 'subTitle' }]);
      }
      setSubTitle('');
      setErrorMessage('');
      handleCloseSubTitleModal();
      handleFirstButtonClick();
      setEditingIndex(null);
      setEditingType(null);
    }
  };
  // const handleAddsubTitleNew = () => {
  //   if (subTitleNew.trim() === '') {
  //     setErrorMessage('The field is required');
  //   } else {
  //     setPreviewData([...previewData, { text: subTitleNew, type: 'subTitle' }]);
  //     setErrorMessage('');
  //     setSubTitleNew('');
  //     handleCloseSubTitleModalNew();
  //   }
  // };
  const handleAddsubTitleNew = (index) => {
    if (subTitleNew.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // Check if an index is provided
      if (index !== undefined) {
        // Insert the new subtitle at the specified index
        setPreviewData([
          ...previewData.slice(0, index),
          { text: subTitleNew, type: 'subTitle' },
          ...previewData.slice(index),
        ]);
        setNextIndex(undefined);
      } else {
        // If no index is provided, add the subtitle to the end of the array
        setPreviewData([...previewData, { text: subTitleNew, type: 'subTitle' }]);
      }
      setErrorMessage('');
      setSubTitleNew('');
      handleCloseSubTitleModalNew();
      setOpenAddNewModal(false);
      setNextIndex(undefined);
    }
  };

  // pdf file link
  const [link, setLink] = React.useState('');
  const [linkNew, setLinkNew] = React.useState('');
  const [linkModal, setLinkModal] = React.useState(false);
  const [linkModalNew, setLinkModalNew] = React.useState(false);
  const handleOpenLinkModalNew = () => setLinkModalNew(true);
  const handleOpenLinkModal = () => {
    // if (editingIndex !== null && editingType === 'subTitle') {
    // setSubTitle(previewData[editingIndex]?.name);
    setLinkModal(true);
    // }
    // setSubTitleModal(true);
  };
  const handleCloseLinkModal = () => setLinkModal(false);
  const handleCloseLinkModalNew = () => setLinkModalNew(false);
  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };
  const handleLinkChangeNew = (event) => {
    setLinkNew(event.target.value);
  };
  const handleAddLink = () => {
    if (link.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // setPreviewData([...previewData, { name: subTitle, type: 'subTitle' }]);
      // setErrorMessage('');
      // setSubTitle('');
      // handleCloseSubTitleModal();
      // const existingTitleIndex = previewData.findIndex((item) => item.type === 'subTitle');

      if (editingIndex !== -1) {
        const updatedPreviewData = [...previewData];
        updatedPreviewData[editingIndex] = { text: link, type: 'link' };
        setPreviewData(updatedPreviewData);
      } else {
        setPreviewData([...previewData, { text: link, type: 'link' }]);
      }
      setLink('');
      setErrorMessage('');
      handleCloseLinkModal();
      handleFirstButtonClick();
      setEditingIndex(null);
      setEditingType(null);
    }
  };
  const handleAddLinkNew = (index) => {
    if (linkNew.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // Check if an index is provided
      if (index !== undefined) {
        // Insert the new link at the specified index
        setPreviewData((prevData) => [
          ...prevData.slice(0, index),
          { text: linkNew, type: 'link' },
          ...prevData.slice(index),
        ]);
        setOpenAddNewModal(false);
      } else {
        // If no index is provided, add the link to the end of the array
        setPreviewData((prevData) => [...prevData, { text: linkNew, type: 'link' }]);
        setOpenAddNewModal(false);
      }
      setErrorMessage('');
      setLinkNew('');
      handleCloseLinkModalNew();
    }
  };

  // text
  const [text, setText] = React.useState('');
  const [textModal, setTextModal] = React.useState(false);
  // const handleOpenTextModal = () => setTextModal(true);
  const handleOpenTextModal = () => {
    // if (editingIndex !== null && editingType === 'text') {
    // setText(previewData[editingIndex]?.name);
    setTextModal(true);
    // }
    // setSubTitleModal(true);
  };
  const handleCloseTextModal = () => setTextModal(false);
  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleAddText = () => {
    if (text.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // setPreviewData([...previewData, { name: subTitle, type: 'subTitle' }]);
      // setErrorMessage('');
      // setSubTitle('');
      // handleCloseSubTitleModal();
      // const existingTitleIndex = previewData.findIndex((item) => item.type === 'subTitle');

      if (editingIndex !== -1) {
        const updatedPreviewData = [...previewData];
        updatedPreviewData[editingIndex] = { text, type: 'text' };
        setPreviewData(updatedPreviewData);
      } else {
        setPreviewData([...previewData, { text, type: 'text' }]);
      }
      setText('');
      setErrorMessage('');
      handleCloseTextModal();
      handleFirstButtonClick();
      setEditingIndex(null);
      setEditingType(null);
    }
  };
  const [textNew, setTextNew] = React.useState('');
  const [textModalNew, setTextModalNew] = React.useState(false);
  const handleOpenTextModalNew = () => setTextModalNew(true);
  const handleCloseTextModalNew = () => setTextModalNew(false);
  const handleTextChangeNew = (event) => {
    setTextNew(event.target.value);
  };
  const handleAddTextNew = (index) => {
    if (textNew.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // Check if an index is provided
      if (index !== undefined) {
        // Insert the new text at the specified index
        setPreviewData([...previewData.slice(0, index), { text: textNew, type: 'text' }, ...previewData.slice(index)]);
        setNextIndex(undefined);
      } else {
        // If no index is provided, add the text to the end of the array
        setPreviewData([...previewData, { text: textNew, type: 'text' }]);
      }
      setErrorMessage('');
      setTextNew('');
      handleCloseTextModalNew();
      setOpenAddNewModal(false);
      setNextIndex(undefined); // Reset the nextIndex state
    }
  };

  //  image
  const [imageModal, setImageModal] = React.useState(false);
  const handleOpenImageModal = () => setImageModal(true);
  const handleCloseImageModal = () => setImageModal(false);
  const [imageData, setimageData] = React.useState({ solutionImage: null });

  const handelImageChange = (e) => {
    const { name, files } = e.target;
    setimageData({
      ...imageData,
      [name]: name === 'solutionImage' ? files[0] : e.target.value,
    });
  };

  const handleAddImage = () => {
  
    if (imageData?.solutionImage) {
      // Replace the existing image in the previewData array
      const updatedPreviewData = [...previewData];
      updatedPreviewData[editingIndex] = { text: imageData?.solutionImage, type: 'image' };
      setPreviewData(updatedPreviewData);

      // Clear the imageData state
      setimageData({ solutionImage: null });

      if((imageData?.solutionImage instanceof File) ){
        if(!(deletedEditItems?.text instanceof File)){
          if(deletedEditItems?.type==="image"){
            setDeletedItems([...deletedItems,deletedEditItems])
          }
        }
           
      }
    }
    handleCloseImageModal();
  };

  const [imageModalNew, setImageModalNew] = React.useState(false);
  const handleOpenImageModalNew = () => setImageModalNew(true);
  const handleCloseImageModalNew = () => setImageModalNew(false);
  const [imageDataNew, setimageDataNew] = React.useState({ solutionImageNew: null });
  const handelImageChangeNew = (e) => {
    const { name, files } = e.target;
    setimageDataNew({
      ...imageDataNew,
      [name]: name === 'solutionImageNew' ? files[0] : e.target.value,
    });
  };

  const handleAddImageNew = (index) => {
    if (imageDataNew?.solutionImageNew) {
      // Check if an index is provided
      if (index !== undefined) {
        // Insert the new image at the specified index
        setPreviewData((prevData) => [
          ...prevData.slice(0, index),
          { text: imageDataNew?.solutionImageNew, type: 'image' },
          ...prevData.slice(index),
        ]);
        setNextIndex(undefined);
        setOpenAddNewModal(false);
      } else {
        // If no index is provided, add the image to the end of the array
        setPreviewData((prevData) => [...prevData, { text: imageDataNew?.solutionImageNew, type: 'image' }]);
        setNextIndex(undefined);
        setOpenAddNewModal(false);
      }
    }
    setimageDataNew(null);
    handleCloseImageModalNew();
  };

  //  pdf file
  const [PDFModal, setPDFModal] = React.useState(false);
  const handleOpenPDFModal = () => setPDFModal(true);
  const handleClosePDFModal = () => setPDFModal(false);
  const [PDFData, setPDFData] = React.useState({ solutionPDF: null });

  const handelPDFChange = (e) => {
    const { name, files } = e.target;
    setPDFData({
      ...PDFData,
      [name]: name === 'solutionPDF' ? files[0] : e.target.value,
    });
  };

  const handleAddPDF = () => {
    
    if (PDFData?.solutionPDF) {
      // Replace the existing image in the previewData array
      const updatedPreviewData = [...previewData];
      updatedPreviewData[editingIndex] = { text: PDFData?.solutionPDF, type: 'link' };
      setPreviewData(updatedPreviewData);

      // Clear the imageData state
      setPDFData({ solutionPDF: null });

      
      if((PDFData?.solutionPDF instanceof File) ){
        if(!(deletedEditItems?.text instanceof File)){
          if(deletedEditItems?.type==="link"){
            setDeletedItems([...deletedItems,deletedEditItems])
          }
        }
           
      }
    }
    handleClosePDFModal();
  };

  const [PDFModalNew, setPDFModalNew] = React.useState(false);
  const handleOpenPDFModalNew = () => setPDFModalNew(true);
  const handleClosePDFModalNew = () => setPDFModalNew(false);
  const [PDFDataNew, setPDFDataNew] = React.useState({ solutionPDFNew: null });
  const handelPDFChangeNew = (e) => {
    const { name, files } = e.target;
    setPDFDataNew({
      ...PDFDataNew,
      [name]: name === 'solutionPDFNew' ? files[0] : e.target.value,
    });
  };

  const handleAddPDFNew = (index) => {
    if (PDFDataNew?.solutionPDFNew) {
      // Check if an index is provided
      if (index !== undefined) {
        // Insert the new image at the specified index
        setPreviewData((prevData) => [
          ...prevData.slice(0, index),
          { text: PDFDataNew?.solutionPDFNew, type: 'link' },
          ...prevData.slice(index),
        ]);
        setNextIndex(undefined);
        setOpenAddNewModal(false);
      } else {
        // If no index is provided, add the image to the end of the array
        setPreviewData((prevData) => [...prevData, { text: PDFDataNew?.solutionPDFNew, type: 'link' }]);
        setNextIndex(undefined);
        setOpenAddNewModal(false);
      }
    }
    setPDFDataNew(null);
    handleClosePDFModalNew();
  };

  // video
  const [videoModal, setVideoModal] = React.useState(false);
  const handleOpenVideoModal = () => setVideoModal(true);
  const handleCloseVideoModal = () => setVideoModal(false);
  const [youtubeVideo, setYoutubeVideo] = React.useState('');
  const [videoData, setVideoData] = React.useState({ solutionVideo: null });
  const handleYoutubeChange = (event) => {
    setYoutubeVideo(event.target.value);
  };
  const handelVideoChange = (e) => {
    const { name, files } = e.target;

    setVideoData({
      ...videoData,
      [name]: name === 'solutionVideo' ? files[0] : e.target.value,
    });
  };
  const handleAddVideo = () => {
    if (youtubeVideo.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // setPreviewData([...previewData, { name: subTitle, type: 'subTitle' }]);
      // setErrorMessage('');
      // setSubTitle('');
      // handleCloseSubTitleModal();
      // const existingTitleIndex = previewData.findIndex((item) => item.type === 'subTitle');

      if (editingIndex !== -1) {
        const updatedPreviewData = [...previewData];
        updatedPreviewData[editingIndex] = { text: youtubeVideo, type: 'youTube' };
        setPreviewData(updatedPreviewData);
      } else {
        setPreviewData([...previewData, { text: youtubeVideo, type: 'youTube' }]);
      }
      setYoutubeVideo('');
      setErrorMessage('');
      handleCloseVideoModal();
      handleFirstButtonClick();
      setEditingIndex(null);
      setEditingType(null);
    }
  };

  const [videoModalNew, setVideoModalNew] = React.useState(false);
  const handleOpenVideoModalNew = () => setVideoModalNew(true);
  const handleCloseVideoModalNew = () => setVideoModalNew(false);
  const [youtubeVideoNew, setYoutubeVideoNew] = React.useState('');

  const handleYoutubeChangeNew = (event) => {
    setYoutubeVideoNew(event.target.value);
  };
  // const handelVideoChange = (e) => {
  //   const { name, files } = e.target;

  //   setVideoData({
  //     ...videoData,
  //     [name]: name === 'solutionVideo' ? files[0] : e.target.value,
  //   });
  // };
  const handleAddVideoNew = (index) => {
    if (youtubeVideoNew.trim() === '') {
      setErrorMessage('The field is required');
    } else {
      // Check if an index is provided
      if (index !== undefined) {
        // Insert the new YouTube video at the specified index
        setPreviewData((prevData) => [
          ...prevData.slice(0, index),
          { text: youtubeVideoNew, type: 'youTube' },
          ...prevData.slice(index),
        ]);
        setOpenAddNewModal(false);
      } else {
        // If no index is provided, add the YouTube video to the end of the array
        setPreviewData((prevData) => [...prevData, { text: youtubeVideoNew, type: 'youTube' }]);
        setOpenAddNewModal(false);
      }
      setErrorMessage('');
      setYoutubeVideoNew('');
      handleCloseVideoModalNew();
    }
  };

  
  const handleDeleteItem = (index, item) => {
    const updatedPreviewData = [...previewData];
    updatedPreviewData.splice(index, 1);
    setPreviewData(updatedPreviewData);
    setDeletedItems(prevDeletedItems => [...prevDeletedItems, item]);
  };
  


  const handleSave = async () => {
    try {
      setLoading(true);
  
    
      deletedItems.map( async(item)=>{
        if(typeof item.text==="string"){

        
        if (item.type === "image" || item.type==="avatar" || item.type==="link") {
          const header = {
            headers: {
              'Content-Type': 'application/json',
            },
          };
          const data = {
            filename:item.text
          }
          const delUploads = await deleteUploads(data , header); 
          console.log(delUploads)
        }}
      })
      
      // Ensure category is provided
      if (!category) {
        throw new Error('Category is required');
      }
  
      // Initialize an array to hold the processed data
      const processedData = [];
  
      // Process each item in previewData
      const processedItems = await Promise.all(
        previewData.map(async (item) => {
          let newItem;
          if (item.text instanceof File) {

            const headers = {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            };
            // If the item is a file, upload it using the upload API
            const data = new FormData();
            data.append('image', item.text);
  
            const response = await uploadFile(data , headers); // Assuming uploadFile returns a promise that resolves with the response
            newItem = {...item, text: response.data }; // Update the item's text with the response from the upload API
          } else {
            // For other types, just copy the item as is
            newItem = item;
          }
          return newItem;
        })
      );
  
      // Now, processedItems contains all items with potentially updated text for files
      const titleObject = processedItems.find((item) => item.type === 'title');
      const title = titleObject? titleObject.text : ''; // Default to empty string if titleObject is not found
      const avatarObject = processedItems.find((item) => item.type === 'avatar');
      const avatar = avatarObject? avatarObject.text : ''; // Default to empty string if avatarObject is not found
 
      // Creating the data object
      const data = {
        categoryId: category,
        title,
        avatar,
        solutions: processedItems, // Use the processed items directly
      };
  
      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await updateSolution(Data?._id, data, headers);
  
      Swal.fire({
        icon: 'success',
        title: 'Solution Updated successfully',
      });
  
      setLoading(false);
    } catch (error) {
      console.error('Error updating solution:', error.response? error.response.data : error.message);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
      });
    }
  };
  

  // const handleSave = async () => {
  //   try {
  //     setLoading(true);

  //     // Ensure category is provided
  //     if (!category) {
  //       throw new Error('Category is required');
  //     }

  //     const solutionData = [];
  //     console.log('solution data', solutionData);
  //     const uploadedFiles = await Promise.all(
  //       previewData.map(async (item) => {
  //         if (item.text instanceof File) {
  //           // Check if it's a File object
  //           const headers = {
  //             headers: {
  //               'Content-Type': 'multipart/form-data',
  //             },
  //           };
  //           const data = new FormData();
  //           data.append('image', item.text);

  //           const fileResponse = await uploadFile(data, headers);
  //           console.log(fileResponse.data);
  //           solutionData.push({ text: item?.text?.name, type: item.type });
  //         } 
  //          if (typeof item.text === 'string') {
  //           // Check if it's a string
  //           solutionData.push({ text: item.text, type: item.type });
  //         }
  //       })
  //     );

     
  //     const titleObject = solutionData.find((item) => item.type === 'title');
  //     const title = titleObject ? titleObject.text : ''; // Default to empty string if titleObject is not found
  //     const avatarObject = solutionData.find((item) => item.type === 'avatar');
  //     const avatar = avatarObject ? avatarObject.text : ''; // Default to empty string if avatarObject is not found

  //     // Creating the data object
  //     const data = {
  //       categoryId: category,
  //       title,
  //       avatar,
  //       solutions: solutionData,
  //     };

  //     const headers = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     };
  //     const response = await updateSolution(Data?._id, data, headers);

  //     Swal.fire({
  //       icon: 'success',
  //       title: 'Solution Updated successfully',
  //     });

  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error updating solution:', error.response ? error.response.data : error.message);
  //     setLoading(false);
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Error',
  //       text: error.message,
  //     });
  //   }
  // };

  return (
    <>
      <Helmet>
        <title> Update Solution | Page </title>
      </Helmet>

      <Card sx={{ padding: 2 }}>
        <Stack onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mb: 3, mt: 2 }}>
          <FaArrowLeftLong size={24} />
        </Stack>
        <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
          <Stack sx={{ width: '40%' }}>
            <Typography alignSelf={'center'} variant="h4">
              Update Solution
            </Typography>

            <Stack
              spacing={4}
              sx={{
                height: '80vh',
                border: 1,
                borderColor: 'grey.400',
                padding: 2,
                borderRadius: 2,
                alignItems: 'center',
              }}
            >
              <Button
                disabled={previewData.some((item) => item.type === 'avatar')}
                onClick={handleOpenImageLogoModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Solution Avatar
              </Button>
              {/* Image modal */}
              <Modal
                open={imageLogoModal}
                onClose={handleCloseImageLogoModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Image
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {imageLogoData?.solutionImageLogo === null ||
                      typeof imageLogoData?.solutionImageLogo === 'string' ? (
                        <img
                          src={`${appConfig.FILE_URL}${imageLogoData?.solutionImageLogo}`}
                          alt="img"
                          style={{ maxWidth: '250px', borderRadius: 10 }}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(imageLogoData?.solutionImageLogo)}
                          alt="img"
                          style={{ maxWidth: '250px', borderRadius: 10 }}
                        />
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Image
                      <input
                        type="file"
                        accept="image/*"
                        name="solutionImageLogo"
                        style={{ display: 'none' }}
                        onChange={handelImageLogoChange}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddImageLogo}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Image
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              <Modal
                open={imageLogoModalNew}
                onClose={handleCloseImageLogoModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Image
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {imageLogoDataNew?.solutionImageLogoNew && (
                        <img
                          src={URL.createObjectURL(imageLogoDataNew?.solutionImageLogoNew)}
                          alt="img"
                          style={{ maxWidth: '250px', borderRadius: 10 }}
                        />
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Image
                      <input
                        type="file"
                        accept="image/*"
                        name="solutionImageLogoNew"
                        style={{ display: 'none' }}
                        onChange={handelImageLogoChangeNew}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddImageLogoNew}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Image
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              <Button
                // disabled={isDisable}
                disabled
                onClick={handleOpenTitleModal}
                sx={{
                  width: 200,
                  height: 50,

                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Title
              </Button>

              {/* title modal */}
              <Modal
                open={titleModal}
                onClose={handleCloseTitleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Title
                  </Typography>
                  <TextField
                    sx={{
                      mt: 4,
                    }}
                    fullWidth
                    multiline
                    rows={3}
                    id="outlined-basic"
                    label="Enter Title"
                    variant="outlined"
                    value={title}
                    onChange={handleTitleChange}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddTitle}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Title
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>

              <Button
                // disabled={!isDisable}
                onClick={handleOpenSubTitleModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Subtitle
              </Button>
              {/* subtitle modal */}
              <Modal
                open={subTitleModal}
                onClose={handleCloseSubTitleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Sub Title
                  </Typography>
                  <TextField
                    sx={{
                      mt: 4,
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Subtitle"
                    variant="outlined"
                    value={subTitle}
                    onChange={handlesubTitleChange}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddsubTitle}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Subtitle
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>
              <Modal
                open={subTitleModalNew}
                onClose={handleCloseSubTitleModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Sub Title
                  </Typography>
                  <TextField
                    sx={{
                      mt: 4,
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Subtitle"
                    variant="outlined"
                    onChange={handlesubTitleChangeNew}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={() => handleAddsubTitleNew(nextIndex)}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Subtitle
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>
              <Button
                // disabled={!isDisable}
                onClick={handleOpenTextModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Text
              </Button>
              {/* Text modal */}
              <Modal
                open={textModal}
                onClose={handleCloseTextModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Text
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      mt: 4,
                    }}
                    id="outlined-multiline-static"
                    label="Enter text..."
                    multiline
                    rows={5}
                    value={text}
                    onChange={handleTextChange}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddText}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Text
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>
              <Modal
                open={textModalNew}
                onClose={handleCloseTextModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Text
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      mt: 4,
                    }}
                    id="outlined-multiline-static"
                    label="Enter text..."
                    multiline
                    rows={5}
                    onChange={handleTextChangeNew}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={() => handleAddTextNew(nextIndex)}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Text
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>
              <Button
                // disabled={!isDisable}
                onClick={handleOpenImageModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Image
              </Button>
              {/* Image modal */}
              <Modal
                open={imageModal}
                onClose={handleCloseImageModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Image
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {imageData?.solutionImage === null || typeof imageData?.solutionImage === 'string' ? (
                        <img
                          src={`${appConfig.FILE_URL}${imageData?.solutionImage}`}
                          alt="img"
                          style={{ maxWidth: '250px', borderRadius: 10 }}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(imageData?.solutionImage)}
                          alt="img"
                          style={{ maxWidth: '250px', borderRadius: 10 }}
                        />
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Image
                      <input
                        type="file"
                        accept="image/*"
                        name="solutionImage"
                        style={{ display: 'none' }}
                        onChange={handelImageChange}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddImage}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Image
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              <Modal
                open={imageModalNew}
                onClose={handleCloseImageModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Image
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {imageDataNew?.solutionImageNew && (
                        <img
                          src={URL.createObjectURL(imageDataNew?.solutionImageNew)}
                          alt="img"
                          style={{ maxWidth: '250px', borderRadius: 10 }}
                        />
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Image
                      <input
                        type="file"
                        accept="image/*"
                        name="solutionImageNew"
                        style={{ display: 'none' }}
                        onChange={handelImageChangeNew}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={() => handleAddImageNew(nextIndex)}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Image
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              {/* pdf start */}

              <Button
                // disabled={!isDisable}
                onClick={handleOpenPDFModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add PDF File
              </Button>

              <Modal
                open={PDFModal}
                onClose={handleClosePDFModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    PDF
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {PDFData?.solutionPDF === null || typeof PDFData?.solutionPDF === 'string' ? (
                        <Typography color="red" id="modal-modal-title" variant="h4" component="h2">
                          {PDFData?.solutionPDF}
                        </Typography>
                      ) : (
                        <Typography color="red" id="modal-modal-title" variant="h4" component="h2">
                          {PDFData?.solutionPDF?.name}
                        </Typography>
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload PDF
                      <input
                        type="file"
                        accept="application/pdf"
                        name="solutionPDF"
                        style={{ display: 'none' }}
                        onChange={handelPDFChange}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddPDF}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add PDF File
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              <Modal
                open={PDFModalNew}
                onClose={handleClosePDFModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    PDF
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {PDFDataNew?.solutionPDFNew && (
                        <Typography color="red" id="modal-modal-title" variant="h4" component="h2">
                          {PDFDataNew?.solutionPDFNew?.name}
                        </Typography>
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload PDF
                      <input
                        type="file"
                        accept="application/pdf"
                        name="solutionPDFNew"
                        style={{ display: 'none' }}
                        onChange={handelPDFChangeNew}
                      />
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={() => handleAddPDFNew(nextIndex)}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add PDF File
                    </Button>
                  </Stack>
                </Box>
              </Modal>
              {/* pdf end */}

              <Button
                // disabled={!isDisable}
                onClick={handleOpenVideoModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Video
              </Button>
              {/* Video modal */}
              <Modal
                open={videoModal}
                onClose={handleCloseVideoModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Video
                  </Typography>
                  {/* <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {videoData?.solutionVideo && (
                        <Stack sx={{ mt: 2, width: 300 }}>
                          <Player playsInline src={URL.createObjectURL(videoData?.solutionVideo)} />
                        </Stack>
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Video
                      <input
                        type="file"
                        accept="video/mp4"
                        name="solutionVideo"
                        style={{ display: 'none' }}
                        onChange={handelVideoChange}
                      />
                    </Button>
                  </Stack>
                  <Divider sx={{ mt: 2, mb: 2 }}>OR</Divider> */}
                  <TextField
                    onChange={handleYoutubeChange}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Youtube Link"
                    variant="outlined"
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddVideo}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Video
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              <Modal
                open={videoModalNew}
                onClose={handleCloseVideoModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Video
                  </Typography>
                  {/* <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Stack>
                      {videoData?.solutionVideo && (
                        <Stack sx={{ mt: 2, width: 300 }}>
                          <Player playsInline src={URL.createObjectURL(videoData?.solutionVideo)} />
                        </Stack>
                      )}
                    </Stack>
                    <Button
                      sx={{
                        width: 170,
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        '&:hover': {
                          borderColor: '#e50000',
                          boxShadow: 'none',
                        },
                      }}
                      component="label"
                      variant="outlined"
                      size="medium"
                      startIcon={<SlCloudUpload />}
                    >
                      upload Video
                      <input
                        type="file"
                        accept="video/mp4"
                        name="solutionVideo"
                        style={{ display: 'none' }}
                        onChange={handelVideoChange}
                      />
                    </Button>
                  </Stack>
                  <Divider sx={{ mt: 2, mb: 2 }}>OR</Divider> */}
                  <TextField
                    onChange={handleYoutubeChangeNew}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Youtube Link"
                    variant="outlined"
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={() => handleAddVideoNew(nextIndex)}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Video
                    </Button>
                  </Stack>
                </Box>
              </Modal>

              {/* pdf file link */}
              {/* <Button
                // disabled={!isDisable}
                onClick={handleOpenLinkModalNew}
                sx={{
                  width: 200,
                  height: 50,
                  boxShadow: 'none',
                  backgroundColor: '#AC0000',

                  '&:hover': {
                    backgroundColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
              >
                Add Pdf File Link
              </Button>

              <Modal
                open={linkModal}
                onClose={handleCloseLinkModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Pdf File Link
                  </Typography>
                  <TextField
                    sx={{
                      mt: 4,
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Link"
                    variant="outlined"
                    value={link}
                    onChange={handleLinkChange}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleAddLink}
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Link
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal>
              <Modal
                open={linkModalNew}
                onClose={handleCloseLinkModalNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Pdf File Link
                  </Typography>
                  <TextField
                    sx={{
                      mt: 4,
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Link"
                    variant="outlined"
                    onChange={handleLinkChangeNew}
                  />
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={() => handleAddLinkNew(nextIndex)} 
                     
                      sx={{
                        width: 200,
                        height: 50,
                        boxShadow: 'none',
                        backgroundColor: '#e50000',

                        '&:hover': {
                          backgroundColor: '#AC0000',
                          boxShadow: 'none',
                        },
                      }}
                      variant="contained"
                    >
                      Add Link
                    </Button>
                  </Stack>
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={errorMessage}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    action={
                      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        X
                      </Button>
                    }
                  />
                </Box>
              </Modal> */}

              <FormControl sx={{ m: 1, width: 200 }}>
                <InputLabel id="demo-simple-select-autowidth-label">Select Category</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={category}
                  onChange={handleChange}
                  autoWidth
                  label="Select Category"
                >
                  {categories.map((item, i) => {
                    return <MenuItem value={item?._id}>{item?.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Stack sx={{ width: '40%' }}>
            <Typography alignSelf={'center'} variant="h4">
              Preview
            </Typography>
            <Stack
              // spacing={1}
              sx={{
                height: '80vh',
                border: 1,
                borderColor: 'grey.400',
                padding: 3,
                borderRadius: 2,
                overflow: 'auto',
              }}
            >
              {previewData.map((item, index) => {
                if (item.type === 'avatar') {
                  return (
                    <Box key={index} alignSelf="center" mt={3}>
                      {typeof item.text === 'string' ? (
                        <Avatar src={`${appConfig.FILE_URL}${item.text}`} alt="img" sx={{ width: 40, height: 40 }} />
                      ) : (
                        <Avatar src={URL.createObjectURL(item.text)} alt="img" sx={{ width: 40, height: 40 }} />
                      )}
                      {item.type !== 'title' && item.type !== 'avatar' && (
                        <MdDeleteOutline
                          cursor="pointer"
                          color="red"
                          size={20}
                          onClick={() => handleDeleteItem(index,item)}
                        />
                      )}
                      <CiEdit color="green" cursor="pointer" size={20} onClick={() => handleEditItem(index,item)} />
                    </Box>
                  );
                }

                return null;
              })}
              {previewData.map((item, index) => {
                if (item.type !== 'avatar') {
                  return (
                    <div key={index}>
                      {item.type === 'title' && <Typography variant="subtitle2">{item.text}</Typography>}
                      {item.type === 'subTitle' && <Typography variant="subtitle1">{item.text}</Typography>}
                      {/* {item.type === 'link' && (
                        <Typography color={'#2196f3'} variant="subtitle1">
                          {item.text}
                        </Typography>
                      )} */}
                      {item.type === 'text' && (
                        <Typography spacing={1} sx={{ fontSize: 13 }}>
                          {item.text}
                        </Typography>
                      )}
                      {item.type === 'image' && (
                        <Box key={index} alignSelf="center" mt={3}>
                          <Card variant="outlined" sx={{ borderRadius: 2 }}>
                            {typeof item.text === 'string' ? (
                              <img
                                src={`${appConfig.FILE_URL}${item.text}`}
                                alt="img"
                                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                              />
                            ) : (
                              <img
                                src={URL.createObjectURL(item.text)}
                                alt="img"
                                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                              />
                            )}
                          </Card>
                        </Box>
                      )}

                      {item.type === 'link' && (
                        <Box key={index} alignSelf="center" mt={3}>
                          <Card variant="outlined">
                            {typeof item.text === 'string' ? (
                              <embed
                                src={appConfig.FILE_URL + item?.text}
                                width="100%"
                                height="400px"
                                type="application/pdf"
                              />
                            ) : (
                              <embed
                                src={URL.createObjectURL(item?.text)}
                                width="100%"
                                height="400px"
                                type="application/pdf"
                              />
                            )}
                          </Card>
                        </Box>
                      )}
                      {item.type === 'video' && (
                        <Stack>
                          <Card variant="outlined" sx={{ borderRadius: 2, mt: 3 }}>
                            <Player playsInline src={URL.createObjectURL(item.text)} />
                          </Card>
                        </Stack>
                      )}
                      {item.type === 'youTube' && (
                        <Stack>
                          <Card variant="outlined" sx={{ borderRadius: 2, mt: 3 }}>
                            <YoutubeEmbed videoUrl={item?.text} />
                          </Card>
                        </Stack>
                      )}
                      {item.type !== 'title' && item.type !== 'avatar' && (
                        <MdDeleteOutline
                          cursor="pointer"
                          color="red"
                          size={20}
                          onClick={() => handleDeleteItem(index,item)}
                        />
                      )}

                      <CiEdit color="green" cursor="pointer" size={20} onClick={() => handleEditItem(index , item)} />
                      <Tooltip title="ADD">
                        <IoMdAdd
                          color="blue"
                          cursor="pointer"
                          size={20}
                          onClick={() => handleOpenAddNewModal(index + 1)}
                        />
                      </Tooltip>
                    </div>
                  );
                }
                return null;
              })}
            </Stack>
          </Stack>
        </Container>
        <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 6 }}>
          <LoadingButton
            onClick={handleSave}
            loading={loading}
            sx={{
              width: 200,
              height: 50,
              boxShadow: 'none',
              backgroundColor: '#e50000',

              '&:hover': {
                backgroundColor: '#AC0000',
                boxShadow: 'none',
              },
            }}
            variant="contained"
          >
            Update Solution
          </LoadingButton>
        </Stack>
      </Card>

      <Modal
        open={openAddNewModal}
        onClose={handleCloseAddNewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" gap={3} alignItems="center">
            <Button
              onClick={handleOpenSubTitleModalNew}
              sx={{
                width: 200,
                height: 50,
                boxShadow: 'none',
                backgroundColor: '#AC0000',

                '&:hover': {
                  backgroundColor: '#e50000',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              Add Subtitle
            </Button>
            <Button
              // disabled={!isDisable}
              onClick={handleOpenTextModalNew}
              sx={{
                width: 200,
                height: 50,
                boxShadow: 'none',
                backgroundColor: '#AC0000',

                '&:hover': {
                  backgroundColor: '#e50000',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              Add Text
            </Button>
            <Button
              // disabled={!isDisable}
              onClick={handleOpenImageModalNew}
              sx={{
                width: 200,
                height: 50,
                boxShadow: 'none',
                backgroundColor: '#AC0000',

                '&:hover': {
                  backgroundColor: '#e50000',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              Add Image
            </Button>
            <Button
              // disabled={!isDisable}
              onClick={handleOpenPDFModalNew}
              sx={{
                width: 200,
                height: 50,
                boxShadow: 'none',
                backgroundColor: '#AC0000',

                '&:hover': {
                  backgroundColor: '#e50000',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              Add PDF File
            </Button>
            <Button
              // disabled={!isDisable}
              onClick={handleOpenVideoModalNew}
              sx={{
                width: 200,
                height: 50,
                boxShadow: 'none',
                backgroundColor: '#AC0000',

                '&:hover': {
                  backgroundColor: '#e50000',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              Add Video
            </Button>
            {/* <Button
              // disabled={!isDisable}
              onClick={handleOpenLinkModalNew}
              sx={{
                width: 200,
                height: 50,
                boxShadow: 'none',
                backgroundColor: '#AC0000',

                '&:hover': {
                  backgroundColor: '#e50000',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              Add Pdf File Link
            </Button> */}
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default UpdateSolution;
