import { Box, Button, Card, Container, FormLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';

import {updateAuthToken } from '../helper/backend_helper';


const AuthTokenPage = () => {
  const [bannerData, setBannerData] = useState({});

  const [formData, setFormData] = useState({ profileImage: null });
  const [apikey, setApikey] = useState('8af414edacf464d2b51780b8dd25e0e4');
  const [hotelId,setHotelID] = useState('timelesspuglia');
  const [username,setUserName] = useState('admin');
  const [password,setPassword] = useState('g4tRWG$wg4gw');

  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };




  const handleSave = async () => {
    try {
      setLoading(true);
        if(!apikey || !hotelId || !username || !password){
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'All fields are required',
          });
          setLoading(false);
          return;
        }

      const BannerData = {
        password,
        username,
        hotel_id: hotelId,
        api_key: apikey
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateAuthToken(BannerData, Categoryheaders);


      Swal.fire({
        icon: 'success',
        title: 'Auth Token updated successfully',
      });

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: `${error?.response?.data?.error?.error_message}`,
        text: `${error?.response?.data?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Auth Token </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
    
          <Stack
            // onClick={() => navigate(-1)}
            gap={3}
            sx={{ cursor: 'pointer', mb: 3 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography variant="h4">Auth Token</Typography>
          </Stack>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '50ch' },

            }}
            noValidate
            autoComplete="off"
          >
            <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Api Key</FormLabel>
                <TextField label="Enter Api key" id="outlined-size-small" value={apikey} onChange={(e) => setApikey(e.target.value)} />
              </Stack>
            </Stack>
            <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Hotel ID</FormLabel>
                <TextField  label="Enter Hotal Id" id="outlined-size-small" value={hotelId} onChange={(e) => setHotelID(e.target.value)} />
              </Stack>
            </Stack>
            <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
              <Stack>
                <FormLabel sx={{ mb: 1 }}>User Name</FormLabel>
                <TextField  label="Enter User Name" id="outlined-size-small" value={username} onChange={(e) => setUserName(e.target.value)} />
              </Stack>
            </Stack>
            <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Password</FormLabel>
                <TextField  label="Enter Password" id="outlined-size-small" value={password} onChange={(e) => setPassword(e.target.value)} />
              </Stack>
            </Stack>

            {/* <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 2,
                marginTop: 2,
              }}
            >
              <Stack>
                {!formData.profileImage ? (
                  <img
                    src={`${appConfig.FILE_URL}${bannerData?.image}`}
                    alt="img"
                    style={{ maxWidth: '300px', borderRadius: 10 }}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(formData.profileImage)}
                    alt="img"
                    style={{ maxWidth: '300px', borderRadius: 10 }}
                  />
                )}
              </Stack>
              <Button
                sx={{
                  width: 170,
                  color: '#FF0000',
                  borderColor: '#FF0000',
                  '&:hover': {
                    borderColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                component="label"
                variant="outlined"
                size="medium"
                startIcon={<SlCloudUpload />}
              >
                upload Image
                <input
                  type="file"
                  accept="image/*"
                  name="profileImage"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Button>
            </Stack> */}
          </Box>
          <LoadingButton
            sx={{
              // width: 150,
              mt: 4,
              mb: 4,
              boxShadow: 'none',
              backgroundColor: '#3A5239',
              '&:hover': {
                backgroundColor: '#3A5239',
                boxShadow: 'none',
              },
            }}
            variant="contained"
            onClick={handleSave}
            loading={loading}
          >
            Update
          </LoadingButton>
      
      </Card>
    </>
  );
};

export default AuthTokenPage;
