// ChatComponent.js

import React from "react";
import { Input } from "@nextui-org/react";
import { TextField } from "@mui/material";
import styles from "./input.module.css";

const InputComponent = ({ handleSendMessage, setMessage }) => {
  return (
    <div className={styles.chatInputContainer}>
      {/* <Input
        type="text"
        // className={styles.messageInput}
        placeholder="Type your message..."
      /> */}
       <TextField
      
      clearable
      fullWidth
      size="lg"
      placeholder="Type your message..."
      onChange={(e) => setMessage(e.target.value)}
      id="outlined-basic"
      variant="outlined"
    />
      <button className={styles.sendButton} onClick={() => handleSendMessage}>
        Send
      </button>
    </div>
  );
};

export default InputComponent;
