// FileInput.js

import React from 'react';
import { FaPaperclip } from 'react-icons/fa'; // Import attachment icon from react-icons/fa
import styles from './fileInput.module.css';

const FileInput = ({ handleFileChange }) => {
  return (
    <div className={styles.fileInputContainer}>
      <label htmlFor="fileInput" className={styles.attachmentIcon}>
        <FaPaperclip />
        <input type="file" id="fileInput" className={styles.fileInput} onChange={handleFileChange} />
      </label>
    </div>
  );
};

export default FileInput;
