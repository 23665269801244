import { Container, Stack, Typography,Card } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import Chat from '../components/chat/index'









function ChatPage() {
  return (
   <>
   <Helmet>
        <title> Chat | Page </title>
      </Helmet>
      <Container>

      
        <Card>
      <Chat/>

        </Card>
      </Container>
      

   </>
  )
}

export default ChatPage
