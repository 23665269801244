import { BrowserRouter } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import LoginPage from './pages/LoginPage';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
// ----------------------------------------------------------------------
// basename="/moodlift-admin"
export default function App() {
  const user = localStorage.getItem('technisches');
  const [isLoggedin, setIsLoggedin] = useState(user);

  return (
   
    <HelmetProvider>
      <BrowserRouter  
        //  basename="/Technisches-Handbuch-Admin/" 
        >
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          {isLoggedin ? <Router /> : <LoginPage />}
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
    
  );
}
