import { Helmet } from 'react-helmet-async';
import React from 'react';

import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Grid,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';

function NotificationPage() {
  return (
    <>
      <Helmet>
        <title> Notifications | Page </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Notification</Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>
        {/* <Card style={{ padding: 20 }}> */}
          <form>
            <Grid style={{display:'flex',flexDirection:"column"}} container spacing={4}>
              <Grid item xs={12} sm={10} md={5}>
                <Typography variant="h6">Title</Typography>
                <TextField placeholder="Enter Title:" fullWidth />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <Typography variant="h6">Body</Typography>
                <TextField placeholder="Body:" fullWidth />
              </Grid>

              <Grid item xs={12} sm={10} md={5}>
                <Button
                  fullWidth
                  sx={{
                    padding: 2,
                    backgroundColor: '#000000',
                    color: '#E8BF21',
                    '&:hover': {
                      backgroundColor: '#000000',
                      color: '#E8BF21',
                    },
                  }}
                  variant="contained"
                >
                  Send Notification
                </Button>
              </Grid>
            </Grid>
          </form>
        {/* </Card> */}
      </Container>
    </>
  );
}

export default NotificationPage;
