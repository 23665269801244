import React from "react";
import styles from "./messageType.module.css";

const MessageCard = ({ type, content, position }) => {
  let messageContent;
  let cardStyle = styles.card;

  if (position === "right") {
    cardStyle += ` ${styles.right}`;
  } else {
    cardStyle += ` ${styles.left}`;
  }

  switch (type) {
    case "text":
      messageContent = <div className={styles.text}>{content}</div>;
      break;
    case "image":
      // Updated alt text for better accessibility
      messageContent = <img className={styles.image} src={content} alt="User-uploaded content" />;
      break;
    case "voice":
      messageContent = <div className={styles.voice}>{content}</div>;
      break;
    case "file":
      messageContent = <div className={styles.file}>{content}</div>;
      break;
    default:
      messageContent = <div>{content}</div>;
  }

  return <div className={cardStyle}>{messageContent}</div>;
};

export default MessageCard;
