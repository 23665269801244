import React, { useRef, useState, useEffect, useContext } from "react";
// import { Button, Input, MessageBox, SystemMessage } from "react-chat-elements";
// import Icon from "@mui/material/Icon";
// import {
//   getAllUsersActive,
//   getChatList,
//   getAllMessges,
//   sendMessage,
//   uploadFile,
//   getSearchChatList,
// } from "helper/backend_helper";
// import {
//   Autocomplete,
//   Checkbox,
//   CircularProgress,
//   ClickAwayListener,
//   TextField,
//   Tooltip,
// } from "@mui/material";
// import appConfig from "config";
import moment from "moment";
// import { ToastContainer, toast } from "react-toastify";
// import { ArrowBack, Search, Send } from "@material-ui/icons";
// import ReplayIcon from "@mui/icons-material/Replay";
// import * as io from "socket.io-client";
import { Image } from "@nextui-org/react";
// import MessageType from "./MessageType";
import ChatItem from "./chatItem";
import MessageCard from "./MessageType";
import InputComponent from "./TextInput";
import FileInput from "./FileInput";
import styles from "./chat.module.css";
// import MyContext from "../../MyContext";

// const socket = io.connect("https://shypie.codecoytechnologies.live/");

function Chat() {
  const ref = useRef();
  const [media, setMedia] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("text");
  const [showMediaPopup, setShowMediaPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState("");

  // Autoccomplete
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [options, setOptions] = useState([]);

  const loading = openAutoComplete && options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const city = window.localStorage.getItem("shypieCityId");

      // const response = await getAllUsersActive(city);
      // if (active) {
      //   setOptions([...response.data.data]);
      // }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  // Scroll to bottom messages
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    const domNode = messagesEndRef.current;
    if (domNode) {
      domNode.scrollTo({
        top: domNode.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // socket.on("connect", function () {
    //   console.log("check 2", socket.connected);
    // });

    scrollToBottom();
  }, []);

  // Chat list
  const [listLoading, setListLoading] = useState(false);
  const [list, setList] = useState([
    {
      _id: "65373d866751bbd1e653618c",
      lastMessage: "Morning !",
      lastMessageType: "text",
      unReadCount: 0,
      members: [
        {
          location: {
            type: "Point",
            coordinates: [-95.44056923389982, 29.94254545871515],
          },
          _id: "653345246751bbd1e64da353",
          name: "danilo",
          profileImage:
            "image_picker_381B7FA2-CC69-462F-8E96-9A153342A82A-1841-000000A6F4142FDF-1697858847557.jpg",
          phoneNumber: "3467990649",
          dateOfBirth: "1976-10-29T00:00:00.000Z",
          street: "1350 Greens Pkwy",
          apartment: "Apt 837",
          zipCode: "77067",
          state: "Texas",
          vehicleType: null,
          make: "MERZ ",
          model: "ML 350",
          color: null,
          isVehicleInsurance: false,
          licenseState: "Texas",
          licenseNumber: "49345690",
          securityNumber: null,
          licenseImage:
            "image_picker_4CBC96FF-9955-46DF-B059-55305A5A7FCD-1841-000000A940166148-1697858851757.jpg",
          email: "orelisp0@gmail.com",
          password:
            "$2b$12$Eze2SBLe.i07fFxvxlAlG.61EphJp6zYLSUm3r..Jo6Cip3g4WcIC",
          role: {
            _id: "6448cef7e5f8725b33182025",
            name: "user",
            createdAt: "2023-04-26T07:12:55.163Z",
            updatedAt: "2023-04-26T07:12:55.163Z",
            __v: 0,
          },
          isEmailVerified: true,
          isProfileCompleted: false,
          isActive: true,
          balance: 0,
          earning: 0,
          UUID: "",
          violations: 0,
          dropViolations: 0,
          dropViolationsDate: null,
          userCity: "Houston",
          createdAt: "2023-10-21T03:27:32.427Z",
          updatedAt: "2023-10-24T03:45:44.279Z",
          __v: 0,
          city: {
            _id: "64ff6415b75386c5f54787fb",
            name: "Houston",
            createdAt: "2023-09-11T19:01:41.961Z",
            updatedAt: "2023-09-11T19:01:41.961Z",
            __v: 0,
          },
          id: "653345246751bbd1e64da353",
        },
        {
          location: {
            type: "Point",
            coordinates: [0, 0],
          },
          isActive: true,
          UUID: "",
          violations: 0,
          dropViolations: 0,
          dropViolationsDate: null,
          userCity: "",
          _id: "645897450811709d8143c189",
          name: "",
          profileImage: "",
          phoneNumber: "",
          dateOfBirth: "2023-05-08T06:30:28.998Z",
          street: "",
          apartment: "",
          zipCode: "",
          state: "",
          vehicleType: "",
          make: "",
          model: "",
          color: "",
          isVehicleInsurance: false,
          licenseState: "",
          licenseNumber: "",
          securityNumber: "",
          licenseImage: "",
          email: "admin@admin.com",
          password:
            "$2b$12$/eKlSSomSnDrCTIXHVw5uuUjShm3HCNCYcQB4CVDRvAbGbEIMWfKu",
          role: {
            _id: "6448cef7e5f8725b33182026",
            name: "admin",
            createdAt: "2023-04-26T07:12:55.166Z",
            updatedAt: "2023-04-26T07:12:55.166Z",
            __v: 0,
          },
          isEmailVerified: false,
          isProfileCompleted: false,
          balance: 0,
          earning: 0,
          createdAt: "2023-05-08T06:31:33.467Z",
          updatedAt: "2023-07-27T06:02:02.672Z",
          __v: 0,
          city: null,
          id: "645897450811709d8143c189",
        },
      ],
      createdAt: "2023-10-24T03:44:06.473Z",
      updatedAt: "2023-10-24T03:44:06.473Z",
      __v: 0,
    },
    {
      _id: "6535ac096751bbd1e650cf50",
      lastMessage: "Hi there!",
      lastMessageType: "text",
      unReadCount: 1,
      members: [
        {
          location: {
            type: "Point",
            coordinates: [-96.662381, 32.797456],
          },
          _id: "653454256751bbd1e64f0444",
          name: "Juan Rodriguez ",
          profileImage:
            "df6511db-e25b-401a-a58d-75229f46aa716728425982385006316-1697928226937.jpg",
          phoneNumber: "2149702278",
          dateOfBirth: "1981-11-23T00:00:00.000Z",
          street: "2524 e Meadows blvd",
          apartment: "L",
          zipCode: "75150",
          state: "Texas",
          vehicleType: null,
          make: "toyota",
          model: "corolla",
          color: null,
          isVehicleInsurance: false,
          licenseState: "Texas",
          licenseNumber: "49834888",
          securityNumber: null,
          licenseImage:
            "82c89d26-cf84-48c3-9358-97d2d07d50525850399192627209741-1698016349136.jpg",
          email: "juanaugustorodriguez@gmail.com",
          password:
            "$2b$12$j8wjA5wyAPzLeleh6yGBRObstDCe93OGddBRi35y2Bj9ky69ejWMq",
          role: {
            _id: "6448cef7e5f8725b33182025",
            name: "user",
            createdAt: "2023-04-26T07:12:55.163Z",
            updatedAt: "2023-04-26T07:12:55.163Z",
            __v: 0,
          },
          isEmailVerified: true,
          isProfileCompleted: false,
          isActive: true,
          balance: 0,
          earning: 0,
          UUID: "",
          violations: 0,
          dropViolations: 0,
          dropViolationsDate: null,
          userCity: "Dallas",
          createdAt: "2023-10-21T22:43:49.261Z",
          updatedAt: "2023-10-24T03:18:30.814Z",
          __v: 0,
          city: {
            _id: "64a6a2bb4a0fa8dbaec52f4d",
            name: "Dallas",
            createdAt: "2023-07-06T11:17:15.386Z",
            updatedAt: "2023-07-06T11:17:15.386Z",
            __v: 0,
          },
          id: "653454256751bbd1e64f0444",
        },
        {
          location: {
            type: "Point",
            coordinates: [0, 0],
          },
          isActive: true,
          UUID: "",
          violations: 0,
          dropViolations: 0,
          dropViolationsDate: null,
          userCity: "",
          _id: "645897450811709d8143c189",
          name: "",
          profileImage: "",
          phoneNumber: "",
          dateOfBirth: "2023-05-08T06:30:28.998Z",
          street: "",
          apartment: "",
          zipCode: "",
          state: "",
          vehicleType: "",
          make: "",
          model: "",
          color: "",
          isVehicleInsurance: false,
          licenseState: "",
          licenseNumber: "",
          securityNumber: "",
          licenseImage: "",
          email: "admin@admin.com",
          password:
            "$2b$12$/eKlSSomSnDrCTIXHVw5uuUjShm3HCNCYcQB4CVDRvAbGbEIMWfKu",
          role: {
            _id: "6448cef7e5f8725b33182026",
            name: "admin",
            createdAt: "2023-04-26T07:12:55.166Z",
            updatedAt: "2023-04-26T07:12:55.166Z",
            __v: 0,
          },
          isEmailVerified: false,
          isProfileCompleted: false,
          balance: 0,
          earning: 0,
          createdAt: "2023-05-08T06:31:33.467Z",
          updatedAt: "2023-07-27T06:02:02.672Z",
          __v: 0,
          city: null,
          id: "645897450811709d8143c189",
        },
      ],
      createdAt: "2023-10-22T23:11:05.041Z",
      updatedAt: "2023-10-24T03:17:04.696Z",
      __v: 0,
      lastReceiver: "645897450811709d8143c189",
    },
  ]);
  const [totalList, setTotalList] = useState(0);

  // List Pagination
  const [listPage, setListPage] = useState(1);
  const [listSize, setListSize] = useState(70);

  // Current Chat
  const [chat, setChat] = useState(null);
  const [chatLoading, setChatLoading] = useState(false);

  // Message
  const [allMessages, setAllMessages] = useState([
    {
      _id: "652522aec5287c81c491ce4f",
      conversation: "64ed985d1bbfd7bfced82285",
      message: "Hi there! How's your day going?",
      type: "text",
      sender: "64c787277818a5a744cadd67",
      receiver: "645897450811709d8143c189",
      createdAt: "2023-10-10T10:08:46.326Z",
      updatedAt: "2023-10-10T10:08:46.326Z",
      position: "left",
      __v: 0,
    },
    {
      _id: "652522aec5287c81c491ce4f",
      conversation: "64ed985d1bbfd7bfced82285",
      message:
        "Hello! I'm just a computer program, so I don't have feelings, but I'm here and ready to assist you. How can I help you today?",
      type: "text",
      sender: "64c787277818a5a744cadd67",
      receiver: "645897450811709d8143c189",
      createdAt: "2023-10-10T10:08:46.326Z",
      updatedAt: "2023-10-10T10:08:46.326Z",
      position: "right",
      __v: 0,
    },
    {
      _id: "652522aec5287c81c491ce4f",
      conversation: "64ed985d1bbfd7bfced82285",
      message: "I'm feeling a bit bored. Got any interesting facts to share?",
      type: "text",
      sender: "64c787277818a5a744cadd67",
      receiver: "645897450811709d8143c189",
      createdAt: "2023-10-10T10:08:46.326Z",
      updatedAt: "2023-10-10T10:08:46.326Z",
      position: "left",
      __v: 0,
    },
    {
      _id: "652522aec5287c81c491ce4f",
      conversation: "64ed985d1bbfd7bfced82285",
      message:
        "Of course! Did you know that honey never spoils? Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still perfectly edible.",
      type: "text",
      sender: "64c787277818a5a744cadd67",
      receiver: "645897450811709d8143c189",
      createdAt: "2023-10-10T10:08:46.326Z",
      updatedAt: "2023-10-10T10:08:46.326Z",
      position: "right",
      __v: 0,
    },
    {
      _id: "652522aec5287c81c491ce4f",
      conversation: "64ed985d1bbfd7bfced82285",
      message:
        "That's fascinating! I had no idea. Thanks for sharing! By the way, what's your favorite book or movie?",
      type: "text",
      sender: "64c787277818a5a744cadd67",
      receiver: "645897450811709d8143c189",
      createdAt: "2023-10-10T10:08:46.326Z",
      updatedAt: "2023-10-10T10:08:46.326Z",
      position: "left",
      __v: 0,
    },
  ]);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [refesh, setRefesh] = useState(false);

  // Message Pagination
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [totalMessages, setTotalMessages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const getchatList = async (isRefresh) => {
    setListLoading(true);
    // const chats = await getChatList({ page: listPage, size: listSize });

    // setList([...list, ...chats.data.data.conversations]);
    // setTotalList(chats.data.meta.total_documents);
    setListLoading(false);
  };

  const searchChatList = async () => {
    const headersJson = {
      headers: {
        "content-type": "application/json",
      },
    };

    setListLoading(true);
    // const chats = await getSearchChatList({ searchTerm }, headersJson, {
    //   page: 1,
    //   size: 100,
    // });

    // setList(chats.data.data.conversations);
    // setTotalList(chats.data.meta.total_documents);
    setListLoading(false);
  };

  // const { setSharedData }: any = useContext(MyContext);

  // useEffect(() => {
  //   if (chat) {
  //     socket.on("message", (data: any) => {
  //       if (chat?._id === data?.conversation) {
  //         setAllMessages((msgs: any) => [...msgs, data]);
  //         scrollToBottom();
  //       }
  //     });
  //   }
  //   return () => {
  //     socket.off("message");
  //   };
  // }, [socket, chat]);

  useEffect(() => {
    // setSharedData(false);
    getchatList(false);
    return () => {
      // setSharedData(false);
    };
  }, [listPage, refesh]);

  const handleOpenChat = async (element) => {
    /* eslint no-underscore-dangle: 0 */

    if (chat?._id === element._id) {
      return;
    }

    const user = element.members.find((x) => x.role.name !== "admin");
    // setAllMessages([]);
    // socket.emit("signin", user._id);
    setPage(1);
    setChatLoading(true);
    element.unReadCount = 0;
    setChat(element);
    // const response = await getAllMessges(element._id, 1, 10);
    // const sorted = response.data.data.sort((a: any, b: any) => {
    //   if (a.createdAt > b.createdAt) {
    //     return 1;
    //   }
    //   if (b.createdAt > a.createdAt) {
    //     return -1;
    //   }
    //   return 0;
    // });

    // setAllMessages(sorted);
    // setTotalMessages(0);
    // setTotalMessages(response.data.meta.total_documents);
    setChatLoading(false);
    scrollToBottom();
  };

  const handleNextChat = async () => {
    // const element = e.target;
    // if (element.scrollTop === 0) {
    setChatLoading(true);
    // const response = await getAllMessges(chat._id, page + 1, size);
    // setPage(page + 1);
    // const sorted = response.data.data.sort((a: any, b: any) => {
    //   if (a.createdAt > b.createdAt) {
    //     return 1;
    //   }
    //   if (b.createdAt > a.createdAt) {
    //     return -1;
    //   }
    //   return 0;
    // });

    // setAllMessages([...sorted, ...allMessages]);
    setChatLoading(false);
    // scrollToBottom();
    // }
  };

  // SEND MESSAGE
  const handleSendMessage = async (event) => {
    event.preventDefault();

    if (!message && !media) {
      return;
    }
    try {
      const user = chat.members.find((x) => x.role.name !== "admin");

      const data = {
        conversation: chat?._id,
        receiver: user?._id,
        senderName: user?.name,
        senderImage: user?.profileImage,
        message,
        type: messageType,
        position: "right",
      };

      const headersJson = {
        headers: {
          "content-type": "application/json",
        },
      };
      setSendMessageLoading(true);
      // console.log(data);
      setAllMessages([...allMessages, data]);
      scrollToBottom();

      if (messageType === "text") {
        // const response = await sendMessage(data, headersJson);
        // setAllMessages([...allMessages, response.data.data]);
        // await socket.emit("message", response.data.data);
        setMessage("");
      }

      if (messageType !== "text") {
        const headers = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        const formData = new FormData();
        formData.append("file", media);
        // const responseFile = await uploadFile(formData, headers);

        // data.message = responseFile.data.file.filename;
        // const response = await sendMessage(data, headersJson);
        // setAllMessages([...allMessages, response.data.data]);
        // await socket.emit("message", response.data.data);

        setMedia(null);
        setShowMediaPopup(false);
        setMessageType("text");
      }

      scrollToBottom();
      event.target.reset();
      setSendMessageLoading(false);
    } catch (error) {
      setSendMessageLoading(false);
      // toast.error(error.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };

  // function handleSelectMedia(file) {
  //   // console.log(file);
  //   if (file) {
  //     setMedia(file);
  //     const type = file.type.split("/")[0];

  //     setMessageType(type);
  //     if (type === "audio") {
  //       setMessageType("voice");
  //     }
  //     if (type === "application") {
  //       setMessageType("file");
  //     }
  //     return setShowMediaPopup(true);
  //   }
  //   setMedia(null);
  //   setShowMediaPopup(false);
  //   setMessageType("text");
  // }

  function handleSelectMedia(file) {
    // console.log(file);
    if (file) {
      setMedia(file);
      const type = file.type.split("/")[0];
  
      setMessageType(type);
      if (type === "audio") {
        setMessageType("voice");
      }
      if (type === "application") {
        setMessageType("file");
      }
      setShowMediaPopup(true);
      return; // No need for this return statement
    }
  
    setMedia(null);
    setShowMediaPopup(false);
    setMessageType("text");
    // No need for the return statement here
  }
  
  

  function handleMediaCancel() {
    setMedia(null);
    setShowMediaPopup(false);
    setMessageType("text");
  }

  const openChatUser = chat?.members.find((x) => x.role.name !== "admin");

  return (
    <div className={styles.contentChat}>
      <div className={styles.contentChatUser}>
        <div className={styles.headSearchChat}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 10,
            }}
          >
            {/* <ArrowBack
              style={{ cursor: "pointer", gap: 10 }}
              onClick={() => navigate(-1)}
            /> */}

            <h4 className={styles.textCenter}>Inbox</h4>
          </div>
          {/* <Tooltip title="Quick refresh">
            <ReplayIcon
              onClick={() => {
                setList([]);
                setListPage(1);
                setRefesh(!refesh);
              }}
            />
          </Tooltip> */}
        </div>
        {/* <ToastContainer /> */}
        <div
          style={{
            display: "flex",
            gap: 10,
            margin: 10,
            cursor: "pointer",
            alignItems: "center",
          }}
        >
          {/* <TextField
            variant="outlined"
            size="small"
            type="text"
            name="searchTerm"
            fullWidth
            placeholder="search by name"
            inputProps={{
              style: {
                height: "15px",
              },
            }}
            onChange={(e) => {
              if (!e.target.value) {
                setSearchTerm(e.target.value);
                setList([]);
                setListPage(1);
                setRefesh(!refesh);
              } else setSearchTerm(e.target.value);
            }}
          /> */}
          {/* <Tooltip title="Search">
            <Search onClick={() => searchTerm && searchChatList()} />
          </Tooltip> */}
        </div>
        <div className={styles.listSearchUserChat}>
          {/* {listLoading && <CircularProgress size="1rem" color="error" />} */}

          {list.map((element) => {
            const item = element.members.find((x) => x.role.name !== "admin");
            return (
              <div
                className={`${styles.userChat} ${
                  element._id === chat?._id ? "active" : ""
                }`}
                data-username="Maria Dennis"
                key={element._id}
              >
                <ChatItem
                  // avatarFlexible
                  onClick={() => handleOpenChat(element)}
                  avatar="https://i.pravatar.cc/150?u=a042581f4e29026704d"
                  alt={item?.name}
                  title={item?.name}
                  subtitle={
                    element?.lastMessageType !== "text"
                      ? element?.lastMessageType
                      : element?.lastMessage.slice(0, 10)
                  }
                  date={moment(element.updatedAt).format("HH:mm a")}
                  unread={
                    element.lastReceiver === item?._id ? 0 : element.unReadCount
                  }
                />
              </div>
            );
          })}
          {/* {totalList > listSize && !listLoading && (
            <Button
              text="Load More Conversations"
              title="Load More Conversations"
              onClick={() => setListPage(listPage + 1)}
              backgroundColor="#FF6341"
            />
          )} */}
        </div>
      </div>

      <div
        className={`${styles.contentChatMessageUser} ${
          !chat ? `${styles.active}` : ""
        }`}
        data-username="Maria Dennis"
      >
        <div className={styles.headChatMessageUserStart}>
          <div className={styles.messageUserProfile}>
            <p className="mt-0 mb-0 text-white">
              <strong style={{ color: "white" }}>Start Chat</strong>
            </p>
          </div>
        </div>
      </div>

      <div
        className={`${styles.contentChatMessageUser} ${
          chat ? `${styles.active}` : ""
        }`}
        data-username="Maria Dennis"
      >
        <div className={styles.headChatMessageUser}>
          <Image
            // src={`${appConfig.FILe_URL}${openChatUser?.profileImage}`}
            src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
            alt=""
            width={50}
            height={50}
          />
          <div className={styles.messageUserProfile}>
            <p className="mt-0 mb-0">
              <strong>{openChatUser?.name}</strong>
            </p>
            <small>
              {/* <p className="offline mt-0 mb-0" /> */}
              {/* Offline */} 08:16 am
            </small>
          </div>
        </div>
        <div className={styles.bodyChatMessageUser} ref={messagesEndRef}>
          {/* {chatLoading && <CircularProgress size="1rem" color="error" />} */}

          {totalMessages > size && allMessages.length < totalMessages && (
            <div
              onClick={() => handleNextChat()}
              onKeyDown={() => handleNextChat()}
              role="button"
              tabIndex={0}
              style={{ cursor: "pointer" }}
            >
              {/* <SystemMessage text="View Previous Messages" /> */}
            </div>
          )}

          {allMessages.map((item, index) => (
            <div className={styles.userChat} key={item._id}>
              {/* <MessageType
                styles={{ background: "#49a3f1", color: "white" }}
                position={
                  openChatUser?._id === item.receiver ? "right" : "left"
                }
                type={item.type}
                message={item.message}
                date={moment(item.createdAt).format()}
              /> */}
              <MessageCard
                position={item.position}
                key={index}
                type={item.type}
                content={item.message}
              />
            </div>
          ))}
        </div>
        <form
          onSubmit={handleSendMessage}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 15,
          }}
        >
          <div className={styles.sendButtons}>
            {messageType === "image" && media && (
              <Image
                src={URL.createObjectURL(media)}
                alt="message_image"
                width={50}
              />
            )}

            {/* <Icon
              fontSize="small"
              onClick={() => ref.current.click()}
              className="icons"
            >
              attachment
            </Icon> */}

            {/* <input
              ref={ref}
              type="file"
              onChange={(e) => handleSelectMedia(e.target.files[0])}
              hidden
              value=""
            /> */}
            <FileInput handleFileChange={(e) => console.log(e.target.file)} />
            {media && (
              <>
                <small>{media?.name.slice(0, 20)}...</small>
                {/* <Icon
                  fontSize="small"
                  onClick={() => handleMediaCancel()}
                  className="icons"
                >
                  cancel
                </Icon> */}
              </>
            )}
            {/* {!media && (
              <div className={styles.footerChatMessageUser}>
                <Input
                  placeholder="Message"
                  multiline
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
              </div>
            )} */}
          </div>
          {!media && (
            <InputComponent
              handleSendMessage={handleSendMessage}
              setMessage={setMessage}
            />
          )}

          {/* {sendMessageLoading ? (
            <CircularProgress size="1rem" color="info" />
          ) : (
            <button type="submit" className="fabutton">
              <Send />
            </button>
          )} */}
        </form>
      </div>
    </div>
  );
}

export default Chat;

/* <div className="user-chat-img">
<img
  src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
  alt=""
/>
<div className="offline" /> 
</div>

<div className="user-chat-text">
<p className="mt-0 mb-0">
  <strong>Maria Dennis</strong>
</p>
<small>Hi, how are you?</small>
</div> */

/* <div className="message-user-left">
<div className="message-user-left-img">
  <img
    src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    alt=""
  />
  <p className="mt-0 mb-0">
    <strong>Maria Dennis</strong>
  </p>
  <small>mié 17:59</small>
</div>
<div className="message-user-left-text">
  <strong>Hola, ¿Cómo estás?</strong>
</div>
</div> */
