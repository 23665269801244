import React from 'react';

const YoutubeEmbed = ({ videoUrl }) => {
  // Function to extract the video ID from the YouTube URL
  // const extractVideoId = (url) => {
  //   const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/))([^#&?]+)/;
  //   const match = url.match(regex);
  //   return match ? match[1] : null;
  // };

  function extractVideoId(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  }

  // Get the video ID from the YouTube URL
  const videoId = extractVideoId(videoUrl);

  return (
    <iframe
      width="100%"
      height="200"
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

export default YoutubeEmbed;
