import { Box, Button, Card, Container, FormLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';

import {
  createBanner,
  createContactInfo,
  deleteUploads,
  getBanner,
  getContactInfo,
  uploadFile,
} from '../helper/backend_helper';
import appConfig from '../config';

const Banner = () => {
  const [bannerData, setBannerData] = useState({});

  const [formData, setFormData] = useState({ profileImage: null });
  const [title, setTitle] = useState('');
  const [whatsApp, setWhatsApp] = useState('');
  const [nonWhatsApp, setNonWhatsApp] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  console.log(startTime);

  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const Banner = async () => {
    try {
      const bannerResponse = await getContactInfo();
      setBannerData(bannerResponse?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Banner();
  }, []);
  useEffect(() => {
    setTitle(bannerData?.name || '');
    setWhatsApp(bannerData?.whatsappNumber || '');
    setNonWhatsApp(bannerData?.nonWhatsappNumber || '');
    setStartTime(bannerData?.startTime || '');
    setEndTime(bannerData?.endTime || '');
  }, [bannerData]);

  const handleSave = async () => {
    try {
      setLoading(true);

      // Check if any field is empty
      if (title.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Name cannot be empty',
        });
        setLoading(false);
        return;
      }
      if (whatsApp.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Whatsapp cannot be empty',
        });
        setLoading(false);
        return;
      }
      if (nonWhatsApp.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'NonWhatsapp cannot be empty',
        });
        setLoading(false);
        return;
      }
      if (startTime.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Start Time cannot be empty',
        });
        setLoading(false);
        return;
      }
      if (endTime.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'End Time cannot be empty',
        });
        setLoading(false);
        return;
      }
      // const bannerResponse = await getBanner();

      // try {
      //   const header = {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   };
      //   const deleteImagedata = {
      //     filename: bannerResponse?.data?.data?.image,
      //   };

      //   const delUploads = await deleteUploads(deleteImagedata, header);

      // } catch (error) {
      //   console.log(error);
      // }
      // Prepare headers for multipart form data
      // const headers = {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // };

      // Create a FormData object
      // const data = new FormData();
      // data.append('image', formData.profileImage);

      // // Upload the image first
      // const imageUploadResponse = await uploadFile(data, headers);

      // // Check if the image upload was successful
      // if (imageUploadResponse.status !== 200) {
      //   throw new Error('Image upload failed');
      // }

      const BannerData = {
        name: title,
        whatsappNumber: whatsApp,
        nonWhatsappNumber: nonWhatsApp,
        startTime,
        endTime,
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await createContactInfo(BannerData, Categoryheaders);

      // setTitle('');
      // setFormData({
      //   ...formData,
      //   profileImage: null,
      // });

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'WebApp Settings updated successfully',
      });

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error while updating Web App Settings',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> WebApp Settings </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          // onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography variant="h4">WebApp Settings</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Name</FormLabel>
              <TextField
                label="Enter Name"
                id="outlined-size-small"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>WhatsApp Number</FormLabel>
              <TextField
                type="number"
                label="Enter Whatsapp number"
                id="outlined-size-small"
                value={whatsApp}
                onChange={(e) => setWhatsApp(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Non Whatsapp Number</FormLabel>
              <TextField
                type="number"
                label="Enter Non whatsapp number"
                id="outlined-size-small"
                value={nonWhatsApp}
                onChange={(e) => setNonWhatsApp(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Start Time</FormLabel>
              <TextField
                label="Enter Start Time"
                id="outlined-size-small"
                type="time" // Ensures 24-hour format is used
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                InputLabelProps={{ shrink: true }} // Keeps label visible
                inputProps={{ step: 300 }} // Optional: 5-minute steps (adjustable)
              />
            </Stack>
          </Stack>

          <Stack spacing={{ xs: 2, sm: 4 }} mt={4} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>End Time</FormLabel>
              <TextField
                label="Enter End Time"
                id="outlined-size-small"
                type="time" // Ensures 24-hour format is used
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                InputLabelProps={{ shrink: true }} // Keeps label visible
                inputProps={{ step: 300 }} // Optional: 5-minute steps (adjustable)
              />
            </Stack>
          </Stack>

          {/* <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 2,
                marginTop: 2,
              }}
            >
              <Stack>
                {!formData.profileImage ? (
                  <img
                    src={`${appConfig.FILE_URL}${bannerData?.image}`}
                    alt="img"
                    style={{ maxWidth: '300px', borderRadius: 10 }}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(formData.profileImage)}
                    alt="img"
                    style={{ maxWidth: '300px', borderRadius: 10 }}
                  />
                )}
              </Stack>
              <Button
                sx={{
                  width: 170,
                  color: '#FF0000',
                  borderColor: '#FF0000',
                  '&:hover': {
                    borderColor: '#e50000',
                    boxShadow: 'none',
                  },
                }}
                component="label"
                variant="outlined"
                size="medium"
                startIcon={<SlCloudUpload />}
              >
                upload Image
                <input
                  type="file"
                  accept="image/*"
                  name="profileImage"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Button>
            </Stack> */}
        </Box>
        <LoadingButton
          sx={{
            // width: 150,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Update
        </LoadingButton>
      </Card>
    </>
  );
};

export default Banner;
