import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import axios from 'axios';
import appConfig from '../../config';
import { createBnBList, createUser } from '../../helper/backend_helper';
import Iconify from '../iconify';

const AddUser = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    bnbLongName: '',
    bnbShortName: '',
    address: '',
    city: '',
    postalCode: null,
    latitude: null,
    longitude: null,
    wifiSsid: '',
    wifiPassword: '',
    contactNo: '',
    googleMapLink: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const data = {
        BnBLongName: formData.bnbLongName,
        BnBShortName: formData.bnbShortName,
        address: formData.address,
        city: formData.city,
        postalCode: formData.postalCode,
        latitude: formData.latitude,
        longitude: formData.longitude,
        wifiSSID: formData.wifiSsid,
        wifiPassword: formData.wifiPassword,
        contactNumber: formData.contactNo,
        googleMapLink: formData.googleMapLink,
      };

      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await createBnBList(data, headers);

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'BnB List created successfully',
      });

      // Clear form inputs
      setFormData({
        bnbLongName: '',
        bnbShortName: '',
        address: '',
        city: '',
        postalCode: null,
        latitude: null,
        longitude: null,
        wifiSsid: '',
        wifiPassword: '',
        contactNo: '',
        googleMapLink: '',
      });

      setLoading(false);
    } catch (error) {
      // console.error('Error:', error.response.data.errors[0].message);
      Swal.fire({
        icon: 'error',
        title: 'error',
        text: `${error.response.data.errors[0].message}`,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> BnB List </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
   
          <Stack
            onClick={() => navigate(-1)}
            gap={3}
            sx={{ cursor: 'pointer', mb: 3 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <FaArrowLeftLong size={24} />
            <Typography variant="h4">Add BnB List</Typography>
          </Stack>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '40ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
              <Stack>
                <FormLabel sx={{ mb: 1 }}>BnB Long Name</FormLabel>
                <TextField
                  name="bnbLongName"
                  label="BnB Long Name"
                  value={formData.bnbLongName}
                  onChange={handleInputChange}
                />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>BnB Short Name</FormLabel>
                <TextField name="bnbShortName" label="BnB Short Name" value={formData.bnbShortName} onChange={handleInputChange} />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Address</FormLabel>
                <TextField name="address" label="Address" value={formData.address} onChange={handleInputChange} />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>City</FormLabel>
                <TextField name="city" label="City" value={formData.city} onChange={handleInputChange} />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Postal Code</FormLabel>
                <TextField
                  name="postalCode"
                  label="Postal Code"
                  value={formData.postalCode}
                  onChange={handleInputChange}
                 type='number'
                />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Latitude</FormLabel>
                <TextField
                  name="latitude"
                  label="Latitude"
                  value={formData.latitude}
                  onChange={handleInputChange}
                 type='number'
                />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Longitude</FormLabel>
                <TextField
                  name="longitude"
                  label="Longitude"
                  value={formData.longitude}
                  onChange={handleInputChange}
                 type='number'
                />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Wifi SSID</FormLabel>
                <TextField
                  name="wifiSsid"
                  label="Wifi SSID"
                  value={formData.wifiSsid}
                  onChange={handleInputChange}
                />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Wifi Password</FormLabel>
                <TextField
                  name="wifiPassword"
                  label="Wifi Password"
                  value={formData.wifiPassword}
                  onChange={handleInputChange}
                />
              </Stack>

              <Stack>
                <FormLabel sx={{ mb: 1 }}>Contact No</FormLabel>
                <TextField
                type='number'
                  name="contactNo"
                  label="Contact No"
                  value={formData.contactNo}
                  onChange={handleInputChange}
                />
              </Stack>
              <Stack>
                <FormLabel sx={{ mb: 1 }}>Google Map Link</FormLabel>
                <TextField
                  name="googleMapLink"
                  label="Google Map Link"
                  value={formData.googleMapLink}
                  onChange={handleInputChange}
                />
              </Stack>











            </Stack>

            <LoadingButton
              sx={{
                width: 150,
                mt: 4,
                mb: 4,
                boxShadow: 'none',
                backgroundColor: '#3A5239',

                '&:hover': {
                  backgroundColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
              onClick={handleSave}
              loading={loading}
            >
              Save
            </LoadingButton>
          </Box>
       
      </Card>
    </>
  );
};

export default AddUser;
