import { Helmet } from 'react-helmet-async';

import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import {
  Avatar,
  Button,
  Container,
  Stack,
  Typography,
  Box,
  TextField,
  Card,
  Table,
  Paper,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { filter } from 'lodash';
import React, { useState } from 'react';
import { sentenceCase } from 'change-case';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import Scrollbar from '../components/scrollbar';
// import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// import Iconify from '../iconify/Iconify';
import appConfig from '../config/index';
import Iconify from '../components/iconify';

const users = [
  {
    position: 'AV Tech ',
    date: '10/11/1234',
   
  },
  {
    position: 'AV Tech',
    date: '10/11/1234',

  },
  {
    position: 'AV Tech',
    date: '10/11/1234',
   
  },
];
const TABLE_HEAD = [
  { id: 'name', label: 'Position', alignRight: true },
  { id: '', label: '', alignRight: true },
  { id: 'email', label: 'Created At', alignRight: true },
  { id: '', label: '', alignRight: true },

  { id: '', label: 'Action', alignRight: true },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function ContractorPositionPage() {
  const navigate = useNavigate();

  const location = useLocation();

  // Now, you can use clickedRowData in this component

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [clickedRow, setClickedRow] = useState(null);
  //  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);


  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  return (
    <>
      <Helmet>
        <title> Contractor Positions </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contractor Positions
          </Typography>

          <Link to={'/contractor-positions/create-position'}>
            <Button
              sx={{
                backgroundColor: '#000000',
                color: '#E8BF21',
                '&:hover': {
                  backgroundColor: '#000000',
                  color: '#E8BF21',
                },
              }}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Create Position
            </Button>
          </Link>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, position, date, action } = row;

                    return (
                      <TableRow  hover key={_id} w>
                        <TableCell component="th" scope="row" padding="2">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={`${appConfig.FILe_URL}${directory}/${image}`} /> */}
                            {/* <Avatar alt={name} src={image} /> */}
                            <Typography align="left" sx={{ cursor: 'pointer' }} variant="subtitle2" noWrap>
                              {position}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{}</TableCell>

                        <TableCell align="left">{date}</TableCell>
                        <TableCell align="left">{}</TableCell>

                        <TableCell align='left' >
                          {
                            <Stack direction={'row'}>
                              <MenuItem >
                                <Iconify icon={'eva:edit-outline'}  />
                                Edit
                              </MenuItem>
                              <MenuItem sx={{ color: 'error.main' }}>
                                <Iconify icon={'eva:trash-2-outline'}  />
                                Delete
                              </MenuItem>
                            </Stack>
                          }
                        </TableCell>

                        {/* <TableCell align="left">
                          {
                            <Label color={(status && 'success') || 'error'}>
                              {sentenceCase(status ? 'Active' : 'Pending')}
                            </Label>
                          }
                        </TableCell> */}
                        {/* <TableCell align="center">
                            {
                              <Label style={{ width: 70 }} color={status === 'active' ? 'success' : 'error'}>
                                {sentenceCase(status === 'active' ? 'Active' : 'Pending')}
                              </Label>
                            }
                          </TableCell> */}

                        {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}

                        {/* <TableCell align="center">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                  {loading && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={7}>
                        <CircularProgress size="20px" />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}

export default ContractorPositionPage;
