import { Helmet } from 'react-helmet-async';
import React from 'react';

import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Grid,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Iconify from '../iconify';

function CreatePosition() {
    const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title> Create Position | Page </title>
      </Helmet>
      <Container>
      <Stack mb={4} direction="row" alignItems="center">
          <Iconify
            onClick={() => navigate(-1)}
            icon={'eva:arrow-back-outline'}
            width={35}
            sx={{ cursor: 'pointer', mr: 3 }}
          />
          <Typography variant="h4">Create Position</Typography>
        </Stack>
        {/* <Card style={{ padding: 20 }}> */}
          <form>
            <Grid style={{display:'flex',flexDirection:"column"}} container spacing={4}>
              <Grid item xs={12} sm={10} md={5}>
                <Typography variant="h6">Position name</Typography>
                <TextField placeholder="Enter Position:" fullWidth />
              </Grid>

              <Grid item xs={12} sm={10} md={5}>
                <Button
                  fullWidth
                  sx={{
                    padding: 2,
                    backgroundColor: '#000000',
                    color: '#E8BF21',
                    '&:hover': {
                      backgroundColor: '#000000',
                      color: '#E8BF21',
                    },
                  }}
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        {/* </Card> */}
      </Container>
    </>
  );
}

export default CreatePosition;
